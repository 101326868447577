import {
  Asset,
  InternalId,
  AssetListScope,
  Metadata,
} from '@cognite/sdk';
import { getAssetList, retrieveAsset } from '../AWS/AWSRequest';
import { sortName } from '../sort';
import { ResourceType } from './SDFDataType';

/**
 * CDF Filter assetsのパラメタ(parentIds)上限値
 * @see https://docs.cognite.com/api/v1/#operation/listAssets
 */
const PARENT_IDS_LIMIT = 100;

/**
 * アセット情報取得
 * CogniteClient.AssetsAPI.listの条件にassetSubtreeIdsを指定した場合の検索処理を再帰処理を活用して実現する。
 * ※assetSubtreeIdsを指定するとキャッシュを活用して取得するため、5分前後ラグが発生するため
 * @param {ResourceType} resourceType リソース種別
 * @param {InternalId[]} ids IDリスト
 * @param {Metadata|undefined} childAssetsMetadata 子アセットの検索条件(metadata)
 * @returns アセット情報リスト
 */
const implementSubtreeAssets = async (
  resourceType: ResourceType,
  ids: InternalId[],
  childAssetsMetadata?: Metadata,
): Promise<{ items: Asset[] }> => {
  const assets = await retrieveAsset(resourceType, ids);

  return {
    items: [
      ...assets.items,
      ...await retrieveSubtreeAssetsForEquipment(resourceType, ids, childAssetsMetadata),
    ],
  };
};

/**
 * アセットツリー情報取得
 * @param {ResourceType} resourceType リソース種別
 * @param {InternalId[]} ids IDリスト
 * @param {Metadata|undefined} metadata 検索条件(metadata)
 * @returns アセットツリー情報
 */
const retrieveSubtreeAssetsForEquipment = async (
  resourceType: ResourceType,
  ids: InternalId[],
  metadata?: Metadata,
): Promise<Asset[]> => {
  // InternalIdを上限数ごとに分割
  const splitInternalIdsByLimit = (ary: InternalId[]): InternalId[][] => {
    const count = Math.ceil(ary.length / PARENT_IDS_LIMIT);
    const dividedArray: InternalId[][] = [];
    for (let i = 0; i < count; i++) {
      const start = i * PARENT_IDS_LIMIT;
      const end = ary.length > (i + 1) * PARENT_IDS_LIMIT ? (i + 1) * PARENT_IDS_LIMIT : ary.length;
      const divideIds = ary.slice(start, end);
      dividedArray.push(divideIds);
    }

    return dividedArray;
  };

  // InternalIdからIDを抽出する
  const extractIds = (internalIds: InternalId[]): number[] => internalIds.map((i) => i.id);

  // CDFの仕様上、parentIdsは100件まで指定可能なため分割してリクエストする
  const splitInternalIds: InternalId[][] = splitInternalIdsByLimit(ids);
  const childAssetPromises: Promise<Asset[]>[] = splitInternalIds.map(async (internalIds) => {
    const filterMetadata = metadata || {};
    const assetListScope: AssetListScope = {
      filter: {
        parentIds: extractIds(internalIds),
        metadata: filterMetadata,
      },
    };

    const childAssets = await getAssetList(resourceType, assetListScope);
    return childAssets;
  });

  const allChildAssets = await Promise.all(childAssetPromises);
  const flatChildAssets = allChildAssets.flat();
  sortName(flatChildAssets);
  if (flatChildAssets.length === 0) {
    return [];
  }

  return [
    ...flatChildAssets,
    ...await retrieveSubtreeAssetsForEquipment(resourceType, flatChildAssets, metadata),
  ];
};

export {
  implementSubtreeAssets,
};
