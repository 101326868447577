import { getAuthentication } from './CacheApi';

/**
 * UIと権限制御のマトリクス
 */
export const AUTHENTICATION_TYPE_MATRIX = {
  EQUIPMENT_LIST: {
    EQUIPMENT_LIST_DETAILS_LINK: 'facility-Read',
    EQUIPMENT_LIST_EDIT_BUTTON: 'managedFacility-Update',
    EQUIPMENT_LIST_ADD_BUTTON: 'managedFacility-Update',
  },
  EQUIPMENT_DETAILS_EQ: {
    EQUIPMENT_LIST_MENU_EQUIPMENT_DETAILS_LINK: 'facility-Read',
    EQUIPMENT_LIST_MENU_ADD_BUTTON: 'facility-Update',
    EQUIPMENT_LIST_MENU_EDIT_BUTTON: 'facility-Update',
    EQUIPMENT_LIST_MENU_UPDATE_BUTTON: 'facility-Read',
    DETAILED_INFORMATION_THREE_D_VIEW: 'facility-Read',
    DETAILED_INFORMATION_X_BUTTON: 'facility-Read',
    DETAILED_INFORMATION_ATTRIBUTE_INFORMATION: 'facility-Read',
    DETAILED_INFORMATION_FILE: 'facility-Read',
    THREE_D_VIEW_POINT_CLOUD: 'facility-Read',
    THREE_D_VIEW_MESH: 'facility-Read',
    THREE_D_VIEW_SHOOTING_POINT: 'facility-Read',
    THREE_D_VIEW_THREE_D_MODEL_DISPLAY: 'facility-Read',
    THREE_D_VIEW_CAMERA_RESET: 'facility-Read',
    FILE_TAB_ADD_BUTTON: 'managedFacility_facilityFile-Upload',
    FILE_TAB_DELETE_BUTTON: 'managedFacility_facilityFile-Delete',
    FILE_TAB_DETAILED_INFORMATION_SEARCH: 'facility-Read',
    FILE_TAB_PHOTO_COMPARISON: 'facility-Read',
    FILE_TAB_LEARNING_PROJECT_SELECTION: 'learningProjectFile-Update',
    FILE_TAB_ADD_LEARNING_PROJECT: 'learningProjectFile-Update',
    FILE_TAB_UPDATE_BUTTON: 'facility-Read',
    FILE_TAB_UPLOAD_FILE_LINK: 'facility-Read',
    EXPANDED_MODAL_EDIT_BUTTON: 'facility-Update',
    EXPANDED_MODAL_DOWNLOAD_BUTTON: 'managedFacility_facilityFile-Download',
  },
  EQUIPMENT_DETAILS_TD: {
    EQUIPMENT_LIST_MENU_EQUIPMENT_DETAILS_LINK: 'teacherData-Read',
    EQUIPMENT_LIST_MENU_ADD_BUTTON: 'teacherData-Update',
    EQUIPMENT_LIST_MENU_EDIT_BUTTON: 'teacherData-Update',
    EQUIPMENT_LIST_MENU_UPDATE_BUTTON: 'teacherData-Read',
    DETAILED_INFORMATION_THREE_D_VIEW: 'teacherData-Read',
    DETAILED_INFORMATION_X_BUTTON: 'teacherData-Read',
    DETAILED_INFORMATION_ATTRIBUTE_INFORMATION: 'teacherData-Read',
    DETAILED_INFORMATION_FILE: 'teacherData-Read',
    THREE_D_VIEW_POINT_CLOUD: 'teacherData-Read',
    THREE_D_VIEW_MESH: 'teacherData-Read',
    THREE_D_VIEW_SHOOTING_POINT: 'teacherData-Read',
    THREE_D_VIEW_THREE_D_MODEL_DISPLAY: 'teacherData-Read',
    THREE_D_VIEW_CAMERA_RESET: 'teacherData-Read',
    FILE_TAB_ADD_BUTTON: 'teacherDataFile-Upload',
    FILE_TAB_DELETE_BUTTON: 'teacherDataFile-Delete',
    FILE_TAB_DETAILED_INFORMATION_SEARCH: 'teacherData-Read',
    FILE_TAB_PHOTO_COMPARISON: 'teacherData-Read',
    FILE_TAB_LEARNING_PROJECT_SELECTION: 'learningProjectFile-Update',
    FILE_TAB_ADD_LEARNING_PROJECT: 'learningProjectFile-Update',
    FILE_TAB_UPDATE_BUTTON: 'teacherData-Read',
    FILE_TAB_UPLOAD_FILE_LINK: 'teacherData-Read',
    EXPANDED_MODAL_EDIT_BUTTON: 'teacherData-Update',
    EXPANDED_MODAL_DOWNLOAD_BUTTON: 'teacherDataFile-Download',
  },
  ANNOTATION: {
    LEARNING_PROJECTS_LIST_LEARNING_PROJECTS_LINK: 'learningProject-Read',
    LEARNING_PROJECTS_LIST_ADD_BUTTON: 'learningProject-Update',
    LEARNING_PROJECTS_LIST_UPDATE_BUTTON: 'learningProject-Read',
    LEARNING_PROJECTS_LIST_DELETE_BUTTON: 'learningProject-Delete',
    LEARNING_PROJECTS_DETAILS_UPDATE_BUTTON: 'learningProject-Read',
    LEARNING_PROJECTS_DETAILS_ANNOTATION_START: 'annotation-Read',
    LEARNING_PROJECTS_DETAILS_REMOVED_FROM_LEARNING_PROJECT: 'learningProjectFile-Delete',
    LEARNING_PROJECTS_DETAILS_DETAILED_INFORMATION_SEARCH: 'learningProject-Read',
    LEARNING_PROJECTS_DETAILS_ADD_CLASS: 'learningProject-Update',
    LEARNING_PROJECTS_DETAILS_REMOVE_CLASS_ADDITION: 'learningProject-Update',
    LEARNING_PROJECTS_DETAILS_FILE_LINK: 'learningProject-Read',
    EXPANDED_MODAL_DOWNLOAD_BUTTON: 'learningProjectFile-Download',
    LEARNING_PROJECTS_NAME_EDIT_BUTTON: 'learningProject-Update',
  },
  MACHINE_LEARNING: {
    LEARNING_PROJECTS_LIST_LEARNING_PROJECTS_LINK: 'learningProject-Read',
    LEARNING_PROJECTS_LIST_ADD_BUTTON: 'learningProject-Update',
    LEARNING_PROJECTS_LIST_UPDATE_BUTTON: 'learningProject-Read',
    LEARNING_PROJECTS_LIST_DELETE_BUTTON: 'learningProject-Delete',
    LEARNING_PROJECTS_DETAILS_UPDATE_BUTTON: 'learningProject-Read',
    LEARNING_PROJECTS_DETAILS_PARAMETER_SETTINGS: 'AILearning-Execution',
    LEARNING_PROJECTS_DETAILS_DETAILED_INFORMATION_SEARCH: 'learningProject-Read',
    LEARNING_PROJECTS_DETAILS_ENSEMBLE_MODEL_CREATION: 'ensembleModel-Create',
    LEARNING_PROJECTS_DETAILS_FILE_LINK: 'learningProject-Read',
    CREATED_LEARNING_MODEL_CONFIGURATION_FILE_LINK: 'learningProject-Read',
    EXPANDED_MODAL_DOWNLOAD_BUTTON: 'learningProjectFile-Download',
    LEARNING_PROJECTS_NAME_EDIT_BUTTON: 'learningProject-Update',
  },
  AI_DETECT: {
    EQUIPMENT_LIST_UPDATE_BUTTON: 'AIDetect-Read',
    EQUIPMENT_LIST_EQUIPMENT_LINK: 'AIDetect-Read',
    EQUIPMENT_LIST_EQUIPMENT_UPDATE: 'AIDetect-Read',
    EQUIPMENT_LIST_AI_PARAMETER_SETTINGS: 'AIDetect-Execution',
    EQUIPMENT_LIST_DELETE_BUTTON: 'managedFacility_facilityFile-Delete',
    EQUIPMENT_LIST_DETAILED_INFORMATION_SEARCH: 'AIDetect-Read',
    EQUIPMENT_LIST_FILE_LINK: 'AIDetect-Read',
    EXPANDED_MODAL_DOWNLOAD_BUTTON: 'AIDetect-Read',
  },
  AI_DETECT_RESULT: {
    DETECTION_RESULT_LIST_UPDATE_BUTTON: 'AIDetectResult-Read',
    DETECTION_RESULTS_LIST_DETECT_RESULT_LINK: 'AIDetectResult-Read',
    DETECTION_RESULTS_LIST_DETECT_RESULT_UPDATE: 'AIDetectResult-Read',
    DETECTION_RESULT_DETAILS_DETAILED_INFORMATION_SEARCH: 'AIDetectResult-Read',
    DETECTION_RESULT_DETAILS_PRESENCE_OR_ABSENCE_OF_DEFECTS: 'AIDetectResultFile-Update',
    DETECTION_RESULT_DETAILS_PREVIOUS_OR_NEXT_IMAGE: 'AIDetectResult-Read',
    DETECTION_RESULT_DETAILS_FILE_LINK: 'AIDetectResult-Read',
    EXPANDED_MODAL_EDIT_BUTTON: 'AIDetectResultFile-Update',
    EXPANDED_MODAL_DOWNLOAD_BUTTON: 'AIDetectResultFile-Download',
  },
  TASK_LIST: {
    LEARNING_TASK_DETECT_TASK: 'train_detect_task-Read',
    LEARNING_TASK_TASK_CONTROL: 'DLStatus-Update',
    LEARNING_TASK_DELETE_BUTTON: 'train_detect_task-Delete',
    LEARNING_TASK_PRIORITY_CHANGE: 'train_detect_task-Update',
    DETECT_TASK_LEARNING_TASK: 'train_detect_task-Read',
    DETECT_TASK_TASK_CONTROL: 'DLStatus-Update',
    DETECT_TASK_PARAMETER: 'train_detect_task-Read',
    DETECT_TASK_DELETE_BUTTON: 'train_detect_task-Delete',
    DETECT_TASK_PRIORITY_CHANGE: 'train_detect_task-Update',
  },
  INSPECTION_RESULTS_LIST: {
    INSPECTION_RESULTS_LIST_ADD_BUTTON: 'inspectionResult-Update',
    INSPECTION_RESULTS_LIST_DELETE_BUTTON: 'inspectionResult-Delete',
    INSPECTION_RESULTS_LIST_INSPECTION_RESULT_DETAILED_LINK: 'inspectionResult-Read',
    INSPECTION_RESULTS_DETAILS_NA_CANCEL: 'inspectionResult-Read',
    INSPECTION_RESULTS_DETAILS_NA_SAVE: 'inspectionResult-Update',
    INSPECTION_RESULTS_DETAILS_NA_THREE_D_VIEW_REFERENCE: 'inspectionResult-Read',
    INSPECTION_RESULTS_DETAILS_NA_REFER_TO_INSPECTION_DETAILS: 'inspectionResult-Read',
    INSPECTION_RESULTS_DETAILS_NA_INSPECTION_CONTENT_REGISTRATION: 'inspectionResult-Update',
    INSPECTION_RESULTS_DETAILS_NA_REGISTRATION_ITEMS: 'inspectionResult-Update',
    INSPECTION_RESULTS_DETAILS_RC_CANCEL: 'inspectionResult-Read',
    INSPECTION_RESULTS_DETAILS_RC_SAVE: 'inspectionResult-Update',
    INSPECTION_RESULTS_DETAILS_RC_THREE_D_VIEW_REFERENCE: 'inspectionResult-Read',
    INSPECTION_RESULTS_DETAILS_RC_REFER_TO_INSPECTION_DETAILS: 'inspectionResult-Read',
    INSPECTION_RESULTS_DETAILS_RC_INSPECTION_CONTENT_REGISTRATION: 'inspectionResult-Update',
    INSPECTION_RESULTS_DETAILS_RC_REGISTRATION_ITEMS: 'inspectionResult-Update',
    INSPECTION_CONTENT_REGISTRATION_MODAL_RC_DELETE_BUTTON: 'inspectionResult-Update',
    INSPECTION_CONTENT_REGISTRATION_MODAL_RC_CANCEL: 'inspectionResult-Read',
    INSPECTION_CONTENT_REGISTRATION_MODAL_RC_SAVE: 'inspectionResult-Update',
    INSPECTION_CONTENT_REGISTRATION_MODAL_RC_REGISTRATION_ITEMS: 'inspectionResult-Update',
  },
  SOLAR_MENU: {
    DATA_REGISTER: {
      SOLAR_MENU_DATA_REGISTER_UPDATE: 'solarDashBoard-Update',
      SOLAR_MENU_DATA_REGISTER_FILE_NAME_DOWNLOAD: 'solarDashBoardFile-Download',
      SOLAR_MENU_DATA_REGISTER_RELOAD_BUTTON: 'solarDashBoard-Read',
    },
    FINANCIAL_REPORT_REGISTER: {
      FINANCIAL_REPORT_FILE_UPLOAD: 'solarDashBoardFile-Upload',
    },
    FINANCIAL_REPORT_SEARCH: {
      FINANCIAL_REPORT_FILE_DOWNLOAD: 'solarDashBoardFile-Download',
      FINANCIAL_REPORT_FILE_DELETE: 'solarDashBoardFile-Delete',
    },
  },
  ELECTRIC_ENERGY_HISTORY: {
    ELECTRIC_ENERGY_HISTORY_SEARCH_BUTTON: 'solarDashBoard-Read',
  },
};

/**
 * UIの権限が含まれているか判定
 * @param uiAuthType UIの権限
 * @returns {boolean} 判定結果(true: 含まれている false: 含まれていない)
 */
export const containsUIAuthType = async (uiAuthType: string): Promise<boolean> => {
  const actionsAuthentication = await getAuthentication();
  return actionsAuthentication.includes(uiAuthType);
};
