import { THREE } from '@cognite/reveal';
import {
  CogniteInternalId,
  FileInfo,
  FileAggregateQuery,
  ItemsResponse,
  FileAggregate,
} from '@cognite/sdk';

import { JsonFile } from './BaseFile';
import { FILE_METADATA_TYPE } from '../common/SDFDataType';

import { postApiGateway } from '../AWS/ApiGateway';
import {
  EP_PATH_INSPECTION_RESULT_FILE_AGGREGATE,
  EP_PATH_INSPECTION_RESULT_FILES,
} from '../AWS/EndpointPath';

export interface Scan {
  id: number,
  targetFacilityId: CogniteInternalId,
  targetFileId: CogniteInternalId,
  damaged: boolean,
  repairIsNeeded?: boolean,
  repaired?: boolean,
  comment?: string,
  point: THREE.Vector3
}

class ScansJsonFile extends JsonFile {
  /*
   * クラスメソッド
   */

  /**
   * 点検内容ファイル数カウント
   * @param {number} assetId
   * @returns ファイル数
   */
  static async countScansFiles(assetId: number): Promise<number> {
    const filter: FileAggregateQuery = {
      filter: {
        assetIds: [assetId],
        metadata: { type: FILE_METADATA_TYPE.INSPECTION_SCANS },
      },
    };

    const scansAggregates = (
      await postApiGateway<FileAggregateQuery, ItemsResponse<FileAggregate>>(
        EP_PATH_INSPECTION_RESULT_FILE_AGGREGATE, filter,
      )
    );

    return scansAggregates.items[0].count;
  }

  /*
   * メンバ変数
   */
  scans: Scan[];

  /*
   * アクセサ
   */
  get linkedManagedFacilityId(): CogniteInternalId | undefined {
    return this.assetIds && this.assetIds.length > 0 ? this.assetIds[0] : undefined;
  }

  set linkedManagedFacilityId(linkedFacilityId: CogniteInternalId | undefined) {
    if (linkedFacilityId) {
      this.assetIds = [linkedFacilityId];
    } else {
      delete this.assetIds;
    }
  }

  /*
   * コンストラクタ
   */
  constructor(fileInfo?: FileInfo) {
    if (fileInfo) {
      super(fileInfo);
    } else {
      super();
    }

    this.scans = [];
  }

  /*
   * メンバメソッド
   */
  async loadContentFromCDF(endpoint: string): Promise<Scan[]> {
    const json: Scan[] = await super.loadContentFromCDF(endpoint);

    const scans: Scan[] = json.map((element) => {
      const damaged = Boolean(element.damaged);
      const repairIsNeeded = Boolean(element.repairIsNeeded);
      const repaired = Boolean(element.repaired);

      const scan: Scan = {
        id: element.id,
        targetFacilityId: Number(element.targetFacilityId),
        targetFileId: Number(element.targetFileId),
        damaged,
        repairIsNeeded,
        repaired,
        comment: element.comment,
        point: element.point,
      };

      return scan;
    });

    return scans;
  }

  async uploadSelfToCDF(): Promise<void> {
    if (!this.linkedManagedFacilityId) {
      throw new Error('リンクする管理設備が指定されていません。');
    }

    if (!this.externalId) {
      this.externalId = `scans.json_${String((new Date()).getTime())}`;
    }

    this.name = 'scans.json';
    this.type = 'scans';

    await super.uploadSelfToCDF(EP_PATH_INSPECTION_RESULT_FILES, JSON.stringify(this.scans));
  }
}

export default ScansJsonFile;
