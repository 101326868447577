import React from 'react';
import {
  Route,
  HashRouter,
  Link,
  // Redirect
} from 'react-router-dom';
import { Layout, Menu, Spin, Popconfirm } from 'antd';
import { Auth } from 'aws-amplify';
import { createBrowserHistory } from "history";

import Home from '../Home/Home';
// import DigitalTwinApp from '../DigitalTwinApp/DigitalTwinApp';
// import Annotation from '../LearningModelManagement/Annotation/Annotation';
// import Learning from '../LearningModelManagement/MachineLearning/Learning';
// import Detect from '../Detect/Detect';
// import DetectResult from '../DetectResult/DetectResult';
// import AnnotationTool from '../AnnotationTool/AnnotationTool';
// import Task from '../Task/Task';
// import InspectionManagement from '../Inspection/InspectionManagement';
// import Dashboard from '../DashBoard/Main';
// import DataRegistrationTabs from '../Solar/DataRegistrationTabs';
// import SolarOperationDashboard from '../SolarDashboard/SolarOperationDashboard';
// import ElectricEnergyHistory from '../Solar/ElectricEnergyHistory';
// import EquipmentInformation from '../Solar/EquipmentInformation';
// import FinancialReportTabs from '../Solar/FinancialReportTabs';
import Lccs from '../Lccs/Lccs';
import { setStorageMenuKey, getStorageMenuKey, removeStorageData } from '../../utils/storageCommon';
import { sdfLogOut } from '../../utils/common';
import { LOGOUT_CONFIRM_MESSAGE } from '../../utils/messages/index'
import {
  // windmillUrls,
  // solarUrls,
  // cogniteChartUrl,
  // powerUrls,
  roadManagementUrl,
} from '../../utils/Links';
import {
  // MenuRow,
  SubMenuRow,
  MenuIcon,
  MenuText
} from './MenuParts';
import './Main.css'

const { Sider, Content, Footer } = Layout;

/**
 * Menu部component
 * @property {object} client CogniteClient
 */
class Main extends React.Component {

  state = {
    collapsed: false,
    selectedPage: 1,
    selectedMenuKey: "1",
    loadingFlag: false,
    authorization: undefined,
  };

  /**
   * Menu展開時(下の">"ボタン)のイベントハンドラ
   * @param {boolean} collapsed
   */
  onCollapse = collapsed => {
    this.setState({
      collapsed
    });
  };

  /**
   * Menuクリック時のイベントハンドラ
   * 選択したMenuのkeyをストレージに格納
   * @param {object} item 選択したMenu Item
   */
  onClickMenu = (item) => {
    this.setSelectedMenuKey(item.key);
  }

  /**
   * 風力・太陽光メニュークリック時のイベントハンドラ
   * 対応するURLを別ウィンドウで開く
   * @param {string} url 対象のURL
   */
  onClickOpenWindow = (url) => {
    window.open(url, null, 'width=500,toolbar=yes,menubar=yes,scrollbars=yes,noreferrer');
  }

  /**
   * 道路メニュー（デジタルレポート）クリック時のイベントハンドラ
   * 対応するURLを別ウィンドウで開く
   * @param {string} url 対象のURL
   */
  onClickOpenWindowRoad = (url, json) => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let randomStr = '';
    for (let i = 0; i < 40; i++) {
      randomStr += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    const encodedStr = btoa(randomStr);
    window.localStorage.setItem(json, encodedStr);
    window.open(url, null, 'width=500,toolbar=yes,menubar=yes,scrollbars=yes,noreferrer');
  }

  /**
   * 道路メニュー（ダッシュボード）クリック時のイベントハンドラ
   * 対応するURLを別ウィンドウで開く
   * @param {string} url 対象のURL
   */
  onClickOpenWindowRoadDashboard = (url) => {
    if (url) {
      window.open(url, null, 'width=500,toolbar=yes,menubar=yes,scrollbars=yes,noreferrer');
    }
  }

  /**
   * ログアウトボタンクリック時のイベントハンドラ
   */
  onClickLogOut = async () => {
    sdfLogOut();
    await Auth.signOut();
  }

  /**
   * MenuKeyの設定
   * @param {string} keyMenuKey
   */
  setSelectedMenuKey = (key) => {
    setStorageMenuKey(key);
    this.setState({
      selectedMenuKey: key
    });
  }

  /**
   * ページ番号の設定
   * @param {number} num 選択しているページ数
   */
  setSelectedPage = (num) => {
    this.setState({
      selectedPage: num
    });
  }

  /**
   * ロードフラグの設定
   * @param {boolean} flag Loading Flag (true: Start Loading / false:Loading Finish)
   */
  setLoadingFlag = (flag) => {
    this.setState({
      loadingFlag: flag
    });
  }

  /**
   * MenuKeyの取得
   * @returns {string} MenuKey
   */
  getMenuKey = () => {
    const history = createBrowserHistory();
    const hash = history.location.hash;

    if (hash === "#/") {
      return "1";
    }
    return getStorageMenuKey();
  }

  /**
   * MenuItemとRoute、FooterMenuの作成
   * @returns {object} MenuItemとRouteの配列オブジェクト、FooterMenuオブジェクト
   */
  generateMenuItemsWithRoute = () => {
    const { collapsed, authorization } = this.state;

    if (!authorization) return { menuItems: [], routes: [], footerMenu: undefined };
    const { accessibleScreenList } = authorization;

    const menuItems = [];
    const routes = [];

    routes.push(
      <Route key="home-key" exact path='/' render={() => (
        <Home client={this.props}
          // selectedPage={this.state.selectedPage}
          // setSelectedPage={this.setSelectedPage}
          // setSelectedMenuKey={this.setSelectedMenuKey}
          setLoadingFlag={this.setLoadingFlag}
        />
      )} />
    );

    // // 設備一覧
    // if (accessibleScreenList.includes('home')) {
    //   menuItems.push(
    //     <Menu.Item key="home-menu">
    //       <MenuRow to="/" icon="./icon/Home.png" title="設備一覧" collapsed={collapsed} />
    //     </Menu.Item>
    //   );
    //   routes.push(
    //     <Route key="home-key" exact path='/' render={() => (
    //       <Home client={this.props}
    //         selectedPage={this.state.selectedPage}
    //         setSelectedPage={this.setSelectedPage}
    //         setSelectedMenuKey={this.setSelectedMenuKey}
    //         setLoadingFlag={this.setLoadingFlag}
    //       />
    //     )} />
    //   );
    // }

    // // 設備詳細
    // if (accessibleScreenList.includes('3DView')) {
    //   menuItems.push(
    //     <Menu.Item key="3D_view-menu">
    //       <MenuRow to="/3DView" icon="./icon/View.png" title="設備詳細" collapsed={collapsed} />
    //     </Menu.Item>
    //   );
    //   routes.push(
    //     <Route key="3D-view-key" path='/3DView' render={() => (
    //       <DigitalTwinApp client={this.props} setLoadingFlag={this.setLoadingFlag} menuCollapsed={collapsed} />
    //     )} />
    //   );
    // }

    // // 学習モデル管理
    // const isDisplayAnnotation = accessibleScreenList.includes('annotation');
    // const isDisplayLearning = accessibleScreenList.includes('learning');
    // if (isDisplayAnnotation || isDisplayLearning) {
    //   const subMenuItems = [];
    //   if (isDisplayAnnotation) {
    //     // アノテーション
    //     subMenuItems.push(
    //       <Menu.Item key="annotation-menu">
    //         <Link to="/Annotation">アノテーション</Link>
    //       </Menu.Item>
    //     );
    //     routes.push(
    //       <Route key="annotation-key" path='/Annotation' render={() => (
    //         <Annotation client={this.props} setLoadingFlag={this.setLoadingFlag} />
    //       )} />
    //     );
    //   }

    //   if (isDisplayLearning) {
    //     // 機械学習
    //     subMenuItems.push(
    //       <Menu.Item key="learning-menu">
    //         <Link to="/Learning">機械学習</Link>
    //       </Menu.Item>
    //     );
    //     routes.push(
    //       <Route key="learning-key" path='/Learning' render={() => (
    //         <Learning client={this.props} setLoadingFlag={this.setLoadingFlag} />
    //       )} />
    //     );
    //   }

    //   menuItems.push(
    //     <SubMenuRow
    //       key="project-submenu-learning-model"
    //       icon="./icon/Annotation.png"
    //       title="学習モデル管理"
    //       collapsed={collapsed}
    //       subMenuItems={subMenuItems}
    //       {...this.props}
    //     />
    //   );
    // }

    // // AI検出
    // if (accessibleScreenList.includes('detect')) {
    //   menuItems.push(
    //     <Menu.Item key="detect-menu">
    //       <MenuRow to="/Detect" icon="./icon/Detect.png" title="AI検出" collapsed={collapsed} />
    //     </Menu.Item>
    //   );
    //   routes.push(
    //     <Route key="detect-key" path='/Detect' render={() => (
    //       <Detect client={this.props} setLoadingFlag={this.setLoadingFlag} menuCollapsed={collapsed} />
    //     )} />
    //   );
    // }

    // // AI検出結果
    // if (accessibleScreenList.includes('detectResult')) {
    //   menuItems.push(
    //     <Menu.Item key="detect_result-menu">
    //       <MenuRow to="/DetectResult" icon="./icon/Result.png" title="AI検出結果" collapsed={collapsed} />
    //     </Menu.Item>
    //   );
    //   routes.push(
    //     <Route key="detect-result-key" path='/DetectResult' render={() => (
    //       <DetectResult client={this.props} setLoadingFlag={this.setLoadingFlag} menuCollapsed={collapsed} />
    //     )} />
    //   );
    // }

    // // タスク一覧
    // if (accessibleScreenList.includes('task')) {
    //   menuItems.push(
    //     <Menu.Item key="aitask-menu">
    //       <MenuRow to="/Task" icon="./icon/AiTask.png" title="タスク一覧" collapsed={collapsed} />
    //     </Menu.Item>
    //   );
    //   routes.push(
    //     <Route key="task-key" path='/Task' render={() => (
    //       <Task setLoadingFlag={this.setLoadingFlag} />
    //     )} />
    //   );
    // }

    // // 点検結果一覧
    // const isDisplayInspectionResults = accessibleScreenList.includes('inspectionResults');
    // if (isDisplayInspectionResults) {
    //   menuItems.push(
    //     <Menu.Item key="inspection-result">
    //       <MenuRow to="/inspection/results" icon="./icon/Inspection.png" title="点検結果一覧" collapsed={collapsed} />
    //     </Menu.Item>
    //   );
    //   routes.push(
    //     <Route key="inspection-result-key" path='/inspection/results' render={() => (
    //       <InspectionManagement client={this.props.client} setLoadingFlag={this.setLoadingFlag} />
    //     )} />
    //   );
    // } else {
    //   // 権限によって非表示となる可能性のある画面は設備一覧画面にリダイレクトするようにする
    //   routes.push(
    //     <Route key="inspection-result-key" path='/inspection/results' render={() => <Redirect to="/" />} />
    //   );
    // }

    // // ダッシュボード
    // const isDisplayDashBoard = accessibleScreenList.includes('dashboard');
    // if (isDisplayDashBoard) {
    //   menuItems.push(
    //     <Menu.Item key="Dashboard-menu">
    //       <MenuRow to="/Dashboard" icon="./icon/Dashboard.png" title="ダッシュボード" collapsed={collapsed} />
    //     </Menu.Item>);
    //   routes.push(
    //     <Route key="dashboard-key" path='/Dashboard' render={() => (
    //       <Dashboard client={this.props.client} setLoadingFlag={this.setLoadingFlag} />
    //     )} />
    //   );
    // } else {
    //   // 権限によって非表示となる可能性のある画面は設備一覧画面にリダイレクトするようにする
    //   routes.push(
    //     <Route key="dashboard-key" path='/Dashboard' render={() => <Redirect to="/" />} />
    //   )
    // }

    // // 電源統合メニュー
    // const isDisplayPowerManagementDashboard = accessibleScreenList.includes('powerManagementDashboard');
    // if (isDisplayPowerManagementDashboard) {
    //   const subMenuItems = [];
    //   // 経営ダッシュボード
    //   subMenuItems.push(
    //     <Menu.Item key="power-management-dashboard" onClick={() => this.onClickOpenWindow(powerUrls.management)} >
    //       経営ダッシュボード
    //     </Menu.Item>
    //   );

    //   menuItems.push(
    //     <SubMenuRow
    //       key="project-submenu-powerIntegration-dashboard"
    //       icon="./icon/PowerIntegration.png"
    //       title="電源統合メニュー"
    //       collapsed={collapsed}
    //       subMenuItems={subMenuItems}
    //       {...this.props}
    //     />
    //   );
    // }

    // // 風力メニュー
    // const isDisplayManagementDashboard = accessibleScreenList.includes('managementDashboard');
    // const isDisplayOperationDashboard = accessibleScreenList.includes('operationDashboard');
    // const isDisplayBirdDetectionDashboard = accessibleScreenList.includes('birdDetectionDashboard');
    // if (isDisplayManagementDashboard || isDisplayOperationDashboard || isDisplayBirdDetectionDashboard) {
    //   const subMenuItems = [];
    //   if (isDisplayManagementDashboard) {
    //     // 経営ダッシュボード
    //     subMenuItems.push(
    //       <Menu.Item key="management-dashboard" onClick={() => this.onClickOpenWindow(windmillUrls.management)} >
    //         経営ダッシュボード
    //       </Menu.Item>
    //     );
    //   }

    //   if (isDisplayOperationDashboard) {
    //     // 運用ダッシュボード
    //     subMenuItems.push(
    //       <Menu.Item key="operation-dashboard" onClick={() => this.onClickOpenWindow(windmillUrls.operation)} >
    //         運用ダッシュボード
    //       </Menu.Item>
    //     );
    //   }

    //   if (isDisplayBirdDetectionDashboard) {
    //     // 鳥検知ダッシュボード
    //     subMenuItems.push(
    //       <Menu.Item key="bird-detection-menu" onClick={() => this.onClickOpenWindow(windmillUrls.birdDetection)} >
    //         鳥検知ダッシュボード
    //       </Menu.Item>
    //     );
    //   }

    //   menuItems.push(
    //     <SubMenuRow
    //       key="project-submenu-windmill-dashboard"
    //       icon="./icon/Windmill.png"
    //       title="風力メニュー"
    //       collapsed={collapsed}
    //       subMenuItems={subMenuItems}
    //       {...this.props}
    //     />
    //   );
    // }

    // // 太陽光メニュー
    // const isDisplaySolarManagementDashboard = accessibleScreenList.includes('solarManagementDashboard');
    // const isDisplaySolarOperationDashboard = accessibleScreenList.includes('solarOperationDashboard');
    // const isDisplaySolarIndividualAnalysis = accessibleScreenList.includes('solarIndividualAnalysis');
    // const isDisplaySolarDataRegistration = accessibleScreenList.includes('solarDataRegistration');
    // const isDisplaySolarElectricEnergyHistory = accessibleScreenList.includes('solarElectricEnergyHistory');
    // const isDisplaySolarEquipmentInformation = accessibleScreenList.includes('solarEquipmentInformation');
    // const isDisplaySolarFinancialReportManagement = accessibleScreenList.includes('solarFinancialReportManagement');

    // const isDisplaySolarMenu = isDisplaySolarManagementDashboard
    //   || isDisplaySolarOperationDashboard
    //   || isDisplaySolarIndividualAnalysis
    //   || isDisplaySolarDataRegistration
    //   || isDisplaySolarElectricEnergyHistory
    //   || isDisplaySolarEquipmentInformation;
    // if (isDisplaySolarMenu) {
    //   const subMenuItems = [];
    //   if (isDisplaySolarManagementDashboard) {
    //     // 経営ダッシュボード
    //     subMenuItems.push(
    //       <Menu.Item key="solar-management-dashboard" onClick={() => this.onClickOpenWindow(solarUrls.management)}>
    //         経営ダッシュボード
    //       </Menu.Item>
    //     );
    //   }

    //   if (isDisplaySolarOperationDashboard) {
    //     // 運用ダッシュボード
    //     subMenuItems.push(
    //       <Menu.Item key="solar-operation-dashboard-menu">
    //         <Link to="/SolarOperationDashboard">運用ダッシュボード</Link>
    //       </Menu.Item>
    //     );
    //     routes.push(
    //       <Route key="solar-operation-dashboard-key" path='/SolarOperationDashboard' render={() => (
    //         <SolarOperationDashboard client={this.props} onClickDashboardMenu={this.onClickOpenWindow} />
    //       )} />
    //     );
    //   } else {
    //     // 権限によって非表示となる可能性のある画面は設備一覧画面にリダイレクトするようにする
    //     routes.push(
    //       <Route key="solar-operation-dashboard-key" path='/SolarOperationDashboard' render={() => <Redirect to="/" />} />
    //     )
    //   }

    //   if (isDisplaySolarIndividualAnalysis) {
    //     // 個別分析
    //     subMenuItems.push(
    //       <Menu.Item key="solar-individual-analysis" onClick={() => this.onClickOpenWindow(cogniteChartUrl)}>
    //         個別分析
    //       </Menu.Item>
    //     );
    //   }

    //   if (isDisplaySolarDataRegistration) {
    //     // データ登録
    //     subMenuItems.push(
    //       <Menu.Item key="solar-dataRegistration">
    //         <Link to="/DataRegistration">データ登録</Link>
    //       </Menu.Item>
    //     );
    //     routes.push(
    //       <Route key="solarDataRegistration" path='/DataRegistration' render={() => (
    //         <DataRegistrationTabs setLoadingFlag={this.setLoadingFlag} />
    //       )} />
    //     );
    //   } else {
    //     // 権限によって非表示となる可能性のある画面は設備一覧画面にリダイレクトするようにする
    //     routes.push(
    //       <Route key="solarDataRegistration" path='/DataRegistration' render={() => <Redirect to="/" />} />
    //     );
    //   }

    //   if (isDisplaySolarElectricEnergyHistory) {
    //     // 発電量ステータス一覧
    //     subMenuItems.push(
    //       <Menu.Item key="solar-electric-energy-history">
    //         <Link to="/SolarElectricEnergyHistory">発電量ステータス一覧</Link>
    //       </Menu.Item>
    //     );
    //     routes.push(
    //       <Route key="solar-electric-energy-history-key" path='/SolarElectricEnergyHistory' render={() => (
    //         <ElectricEnergyHistory />
    //       )} />
    //     );
    //   } else {
    //     // 権限によって非表示となる可能性のある画面は設備一覧画面にリダイレクトするようにする
    //     routes.push(
    //       <Route key="solar-electric-energy-history-key" path='/SolarElectricEnergyHistory' render={() => <Redirect to="/" />} />
    //     )
    //   }

    //   if (isDisplaySolarEquipmentInformation) {
    //     // 設備情報一覧
    //     subMenuItems.push(
    //       <Menu.Item key="solar-equipment-information">
    //         <Link to="/SolarEquipmentInformation">設備情報一覧</Link>
    //       </Menu.Item>
    //     );
    //     routes.push(
    //       <Route key="solar-equipment-information-key" path='/SolarEquipmentInformation' render={() => (
    //         <EquipmentInformation />
    //       )} />
    //     );
    //   } else {
    //     // 権限によって非表示となる可能性のある画面は設備一覧画面にリダイレクトするようにする
    //     routes.push(
    //       <Route key="solar-equipment-information-key" path='/SolarEquipmentInformation' render={() => <Redirect to="/" />} />
    //     )
    //   }

    //   if (isDisplaySolarFinancialReportManagement) {
    //     // 財務帳票管理
    //     subMenuItems.push(
    //       <Menu.Item key="solar-financial-report">
    //         <Link to="/FinancialReport">財務帳票管理</Link>
    //       </Menu.Item>
    //     );
    //     routes.push(
    //       <Route key="solar-financial-report-key" path='/FinancialReport' render={() => (
    //         <FinancialReportTabs setLoadingFlag={this.setLoadingFlag} />
    //       )} />
    //     );
    //   } else {
    //     // 権限によって非表示となる可能性のある画面は設備一覧画面にリダイレクトするようにする
    //     routes.push(
    //       <Route key="solar-financial-report-key" path='/FinancialReport' render={() => <Redirect to="/" />} />
    //     )
    //   }

    //   menuItems.push(
    //     <SubMenuRow
    //       key="project-submenu-solar-dashboard"
    //       icon="./icon/Solar.png"
    //       title="太陽光メニュー"
    //       collapsed={collapsed}
    //       subMenuItems={subMenuItems}
    //       {...this.props}
    //     />
    //   );
    // } else {
    //   // 権限によって非表示となる可能性のある画面は設備一覧画面にリダイレクトするようにする
    //   routes.push(
    //     <Route key="solar-operation-dashboard-key" path='/SolarOperationDashboard' render={() => <Redirect to="/" />} />
    //   )
    // }

    // 道路運営管理
    const isDisplayDigitalTwin = accessibleScreenList.includes('roadManagement');
    if (isDisplayDigitalTwin) {
      const subMenuItems = [];

      if (isDisplayDigitalTwin) {
        let initJson;
        if (this.props.isproject === "true") {
          initJson = this.props.roadproject
        } else {
          initJson = "sample"
        }
        subMenuItems.push(
          <Menu.Item key="road-management-digital-twin" onClick={() => this.onClickOpenWindowRoad(roadManagementUrl + "#" + initJson, initJson)}>
            デジタルレポート
          </Menu.Item>);
        subMenuItems.push(
          <Menu.Item key="road-management-dashboard" onClick={() => this.onClickOpenWindowRoadDashboard(this.props.grafanaurl)}>
            ダッシュボード
          </Menu.Item>);
        subMenuItems.push(
          <Menu.Item key="road-management-lccs">
            <Link to="/Lccs">LCCシミュレーション</Link>
          </Menu.Item>)
        routes.push(
          <Route key="road-management-lccs-key" path='/Lccs' render={() => (
            <Lccs client={this.props.client} setLoadingFlag={this.setLoadingFlag} />
          )} />
        );
      }

      menuItems.push(
        <SubMenuRow
          key="project-submenu-road-management"
          icon="./icon/Road.svg"
          title="道路メニュー"
          collapsed={collapsed}
          subMenuItems={subMenuItems}
          {...this.props}
        />
      );
    }

    // // VIA
    // routes.push(
    //   <Route key="via-key" path='/AnnotationTool' render={() => (
    //     <AnnotationTool client={this.props} />
    //   )} />
    // );

    // const manualSubMenuItems = [];
    // // マニュアル：共通メニュー
    // manualSubMenuItems.push(
    //   <Menu.Item key="common-manual" onClick={() =>
    //     window.open('/SDF_CommonMenu_UserManual.html')
    //   }>
    //     <span>共通メニュー</span>
    //   </Menu.Item>
    // );

    // // マニュアル：電源統合メニュー
    // if (isDisplayPowerManagementDashboard) {
    //   manualSubMenuItems.push(
    //     <Menu.Item key="power-integration-manual" onClick={() =>
    //       window.open('/SDF_PowerIntegrationMenu_UserManual.pdf')
    //     }>
    //       <span>電源統合メニュー</span>
    //     </Menu.Item>
    //   );
    // }

    // // マニュアル：太陽光メニュー
    // if (isDisplaySolarMenu) {
    //   manualSubMenuItems.push(
    //     <Menu.Item key="solar-manual" onClick={() =>
    //       window.open('/SDF_SolarPowerMenu_UserManual.pdf')
    //     }>
    //       <span>太陽光メニュー</span>
    //     </Menu.Item>
    //   );
    // }

    // // マニュアルメニュー
    // const manualMenu = (
    //   <SubMenuRow
    //     key="manual-submenu"
    //     icon="./icon/Manual.png"
    //     title="マニュアル"
    //     collapsed={collapsed}
    //     subMenuItems={manualSubMenuItems}
    //     {...this.props}
    //   />
    // );

    // フッターメニュー
    const footerMenu = (
      <Menu mode="inline" theme={"dark"}>
        {/* {manualMenu} */}
        <Menu.Item key="logout">
          <Popconfirm
            placement="topLeft"
            title={LOGOUT_CONFIRM_MESSAGE}
            onConfirm={this.onClickLogOut}
            okText="OK"
            cancelText="Cancel"
          >
            <div className="footer">
              <MenuIcon src="./icon/Logout.png" alt="ログアウト" />
              <MenuText title='ログアウト' collapsed={collapsed} />
            </div>
          </Popconfirm>
        </Menu.Item>
      </Menu>
    );

    return { menuItems, routes, footerMenu };
  }

  /**
   * component render前の処理
   */
  async componentDidMount() {
    const menuKey = this.getMenuKey();

    const cache = await caches.open('sdf-preset-ap-func');
    const cacheData = await cache.match('/authFunction');
    const data = await cacheData.json();

    this.setState({
      selectedMenuKey: menuKey,
      authorization: data.PermissionFunction,
    });
  }

  /**
   * component破棄時の処理
   * ストレージに格納した情報を削除
   */
  componentWillUnmount() {
    removeStorageData();
  }

  render() {
    const { collapsed } = this.state;
    const { menuItems, routes, footerMenu } = this.generateMenuItemsWithRoute();

    return (
      <div style={{ width: "100%" }}>
        <Spin spinning={this.state.loadingFlag} size="large" tip="Loading..." style={{ marginTop: "10%" }}>
          <Layout style={{ height: '100vh', overflow: 'auto' }}>
            <HashRouter>
              <Sider collapsible collapsed={collapsed} onCollapse={this.onCollapse} style={{ height: '100vh', whiteSpace: "nowrap" }} width={250} className="site-layout-background">
                <Content style={{ overflow: 'auto', height: '88%' }}>
                  <Menu mode="inline" theme={"dark"} selectedKeys={[this.state.selectedMenuKey]} onClick={this.onClickMenu} defaultOpenKeys={['project-submenu-learning-model']}>
                    {menuItems && Object.keys(menuItems).map((item) => menuItems[item])}
                  </Menu>
                </Content>
                <Footer style={{ bottom: "60px", position: "absolute", width: "100%", padding: "0", background: "#001529" }}>
                  {footerMenu}
                </Footer>
              </Sider>
              <Layout style={{ height: '100%', overflow: 'auto' }}>
                {routes && Object.keys(routes).map((item) => routes[item])}
              </Layout>
            </HashRouter>
          </Layout>
        </Spin>
      </div>
    );
  }
}

export default Main;
