/* eslint-disable consistent-return */
import React from 'react';
// import { PublicClientApplication } from '@azure/msal-browser';
// import { CogniteClient } from '@cognite/sdk';
// import { ClientSDKProvider } from '@cognite/gearbox';
import { Auth, Hub } from 'aws-amplify';
import styled from 'styled-components';
import {
  Form,
  Input,
  Button,
  // Modal,
} from 'antd';
import 'antd/dist/antd.css';

import Main from '../Main/Main';
import * as loginPersistance from './persistance';
// import { sdfLogOut } from '../../utils/common';
import { createRequestHeader, createRequestUrl } from '../../utils/AWS/ApiGateway';
import {
  EP_PATH_ANNOTATION_FILES,
  EP_PATH_ANNOTATION_FILES_DOWNLOAD,
  EP_PATH_LEARNING_PJ_FILES_DOWNLOAD,
} from '../../utils/AWS/EndpointPath';
import { hasPermissionToProject, getPermissionToFunction, checkProject, getGrafanaUrl, setSignedCookie } from '../../utils/AWS/AWSRequest';
// import { setTokenExpired } from '../../utils/storageCommon';
// import { azureConfig } from '../../sdf-env.json';
import {
  VALIDATE_ERROR_NO_AUTHORITY,
  VALIDATE_ERROR_TENANT_NAME_REQUIRE,
  // WARNING_TOKEN_TIMEOUT,
} from '../../utils/messages';
import './AuthWrapper.css';

/** ページ全体のコンテナ */
const PageContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FormItem = {
  TenantName: {
    name: 'tenantName',
    label: 'テナント名',
  },
};

// const CLUSTER = 'asia-northeast1-1';
// const BASE_URL = `https://${CLUSTER}.cognitedata.com`;

/**
 * ログイン画面クラス
 */
const AuthWrapper = Form.create({ name: 'login_form' })(

  class extends React.Component {
    constructor() {
      super();

      this.client = undefined;
      this.state = {
        loading: false,
        isLogin: loginPersistance.isCredentialInLocalStorage(),
        isAuthenticated: false,
        isForceRefresh: false,
        isTokenPopup: true,
      };
      // this.reCertification = this.reCertification.bind(this);
      this.isProject = undefined;
      this.roadProject = undefined;
      this.grafanaUrl = undefined;
    }

    /**
     * レンダリング直後に一度だけ呼ばれる。
     * Loginポップアップウィンドウから遷移した場合、認証情報を親ウィンドウに渡してポップアップウィンドウはクローズする。
     * キャッシュにログイン情報が残っている場合、ログイン処理を行う。
     */
    async componentDidMount() {
      this.configureAuthEventListener();
      // Cognitoのトークン取得
      await this.getCognitoToken();

      const { isLogin } = this.state;
      if (isLogin) {
        // 1度ログインした後にブラウザのリロード時に通る。
        // CogniteClientは破棄されているのでキャッシュのログイン情報を
        // 使用してCogniteClientを作成する。
        // トークンの有効期限が切れている場合は認証ポップアップを表示する。
        // 認証処理(CogniteClient作成)が完了するまで画面更新を止める。
        // ⇒CogniteClient作成前に設備詳細画面などに移行してエラーになる

        // this.setState({ isAuthenticated: true });

        this.isProject = await checkProject(loginPersistance.getAuthResultProjectName());
        this.grafanaUrl = await getGrafanaUrl(loginPersistance.getAuthResultProjectName());
        this.roadProject = loginPersistance.getAuthResultProjectName();
        await setSignedCookie(loginPersistance.getAuthResultProjectName());

        // // 認証
        // const token = await this.certification(loginPersistance.getAuthResultProjectName());
        // if (!token) {
        //   // リロード時にアカウント選択をキャンセルした場合は画面遷移を中断
        //   return;
        // }

        this.setState({ isAuthenticated: false });
      }
    }

    /**
     * Cognito認証タイマーハンドラ
     */
    handleRefreshCognitoToken = async () => {
      clearTimeout(this.timerHandlerCognito);
      // Cognitoのトークン更新
      await this.getCognitoToken();
    }

    /**
     * Cognitoのトークン取得・更新
     */
    getCognitoToken = async () => {
      try {
        const session = await Auth.currentSession();
        if (!session.isValid()) {
          // CognitoサインアウトしてPortal画面に戻り、signOutイベントでログアウト処理する
          await Auth.signOut();
          return;
        }

        // AWS IDトークンの更新タイマー作成
        const { idToken: { payload: { exp } } } = session;
        const time = loginPersistance.getCognitoTokenValidityTime(exp);
        this.timerHandlerCognito = setTimeout(this.handleRefreshCognitoToken, time);
      } catch (e) {
        // CognitoサインアウトしてPortal画面に戻り、signOutイベントでログアウト処理する
        await Auth.signOut();
      }
    }

    /**
     * ログインボタンイベントハンドラ
     */
    handleClickLogin = async () => {
      this.setState({ loading: true });
      const { form } = this.props;

      // 入力チェック
      const tenantName = form.getFieldValue(FormItem.TenantName.name);
      if (!tenantName) {
        form.setFields({
          [FormItem.TenantName.name]: {
            value: tenantName, errors: [new Error(VALIDATE_ERROR_TENANT_NAME_REQUIRE)],
          },
        });
        this.setState({ loading: false });
        return;
      }

      const authorityGroup = await hasPermissionToProject(tenantName);
      if (!authorityGroup) {
        form.setFields({
          [FormItem.TenantName.name]: {
            value: tenantName, errors: [new Error(VALIDATE_ERROR_NO_AUTHORITY)],
          },
        });

        this.setState({ loading: false });
        return;
      }

      this.certification(tenantName);

      this.isProject = await checkProject(tenantName);
      this.grafanaUrl = await getGrafanaUrl(tenantName);
      this.roadProject = tenantName;
      await setSignedCookie(tenantName);

      // 認証
      // const token = await this.certification(tenantName);
      // if (!token) {
      //   // CDF-Azure認証でアカウント選択キャンセルした場合はログイン中止
      //   this.setState({ loading: false });
      //   return;
      // }

      const hasPermissionAuth = await getPermissionToFunction(tenantName);
      // キャッシュに各画面/機能アクセスに必要な情報を記録する。
      const cache = await caches.open('sdf-preset-ap-func');
      cache.put('/authFunction', new Response(
        JSON.stringify({
          PermissionFunction: hasPermissionAuth,
        }),
      ));

      this.setState({ isLogin: true, loading: false });
    }


    certification = async (tenantName) => {
      // 認証情報をローカルストレージに格納,
      const persistedAuthResult = loginPersistance.retrieveAuthResult();
      let awsIdToken;
      const session = await Auth.currentSession();
      const { idToken: { jwtToken } } = session;
      awsIdToken = jwtToken;
      loginPersistance.persistAuthResult({
        ...persistedAuthResult,
        project: tenantName,
        awsIdToken,
      });

      // キャッシュにAWSアクセスに必要な情報を記録
      const cache = await caches.open('sdf-preset-ap');
      cache.put('/auth-information', new Response(
        JSON.stringify({
          project: tenantName,
          requestHeader: createRequestHeader(),
          annotationFilesUploadUrl: createRequestUrl(EP_PATH_ANNOTATION_FILES),
          imageFilesDownloadUrl: createRequestUrl(EP_PATH_LEARNING_PJ_FILES_DOWNLOAD),
          annotationFilesDownloadUrl: createRequestUrl(EP_PATH_ANNOTATION_FILES_DOWNLOAD),
        }),
      ));
    };

    /**
     * 認証
     * @param {string} tenantName テナント名
     */
    // certification = async (tenantName) => {
    //   // CDFによって定義されたスコープ
    //   // https://docs.cognite.com/cdf/access/concepts/access_token_scopes.html#operation/getTransformationSchema
    //   const scopes = [
    //     `${BASE_URL}/user_impersonation`,
    //   ];

    //   const { clientId, tenantId } = azureConfig;
    //   // MSAL configuration
    //   const configuration = {
    //     auth: {
    //       clientId,
    //       authority: `https://login.microsoftonline.com/${tenantId}`,
    //     },
    //   };

    //   const pca = new PublicClientApplication(configuration);

    //   const getToken = async () => {
    //     const { isForceRefresh, isTokenPopup } = this.state;
    //     const persistedAuthResult = loginPersistance.retrieveAuthResult();

    //     let account;
    //     let authenticationResult;
    //     let awsIdToken;
    //     try {
    //       // AzureIdTokenの有効期限が切れている場合、localStorageに登録されている情報がundefinedになるためここでAWSのTokenを取得
    //       const session = await Auth.currentSession();
    //       const { idToken: { jwtToken } } = session;
    //       awsIdToken = jwtToken;

    //       const username = persistedAuthResult ? persistedAuthResult.user : undefined;
    //       account = pca.getAccountByUsername(username);
    //       authenticationResult = await pca.acquireTokenSilent({
    //         account,
    //         scopes,
    //         forceRefresh: isForceRefresh,
    //       }).catch(async () => {
    //         let result;
    //         // 初回ログイン、refreshToken期限切れの時は認証ポップアップ
    //         if (isTokenPopup) {
    //           result = await pca.acquireTokenPopup({
    //             account,
    //             scopes,
    //           });
    //         }
    //         return result;
    //       });
    //     } catch (e) {
    //       sdfLogOut();

    //       // 再認証に失敗した場合はポータル画面に遷移
    //       setTokenExpired();
    //       await Auth.signOut();
    //       return;
    //     }

    //     // 再認証でrefreshTokenによる更新ができない場合はここで終了して、
    //     // 再度モーダル表示でユーザー操作による再認証を行う
    //     if (!authenticationResult) return undefined;

    //     loginPersistance.persistAuthResult({
    //       ...persistedAuthResult,
    //       project: tenantName,
    //       user: authenticationResult.account.username,
    //       accessToken: authenticationResult.accessToken,
    //       idToken: authenticationResult.idToken,
    //       awsIdToken,
    //     });

    //     // キャッシュにAWSアクセスに必要な情報を記録する（VIA画面、詳細画面fileUpload）
    //     const cache = await caches.open('sdf-preset-ap');
    //     cache.put('/auth-information', new Response(
    //       JSON.stringify({
    //         project: tenantName,
    //         accessToken: authenticationResult.accessToken,
    //         requestHeader: createRequestHeader(),
    //         annotationFilesUploadUrl: createRequestUrl(EP_PATH_ANNOTATION_FILES),
    //         imageFilesDownloadUrl: createRequestUrl(EP_PATH_LEARNING_PJ_FILES_DOWNLOAD),
    //         annotationFilesDownloadUrl: createRequestUrl(EP_PATH_ANNOTATION_FILES_DOWNLOAD),
    //       }),
    //     ));

    //     // 再認証用タイマー起動
    //     this.reCertificationTimer(authenticationResult.idToken);

    //     return authenticationResult.accessToken;
    //   };

    //   this.client = new CogniteClient({
    //     appId: 'nttcom-digital-twin',
    //     baseUrl: BASE_URL,
    //     project: tenantName,
    //     getToken,
    //   });

    //   // 道路メニュー用プロジェクト取得
    //   this.roadProject = await getRoadProject(tenantName);

    //   // 手動で認証フローをトリガーする(https://docs.cognite.com/dev/guides/sdk/js/authentication/#manually-trigger-authentication)
    //   const token = await this.client.authenticate();
    //   return token;
    // }

    /**
     * 再認証
     */
    // reCertification = async () => {
    //   // 認証
    //   await this.client.authenticate();
    //   // 認証失敗やアカウント選択キャンセル時の対応が別途必要

    //   // 中断している非同期処理を再開
    //   loginPersistance.giveBinarySemaphore();
    // }

    /**
     * 再認証タイムアウト
     */
    // reCertificationTimeout = async () => {
    //   clearTimeout(this.timerHandler);

    //   // CDF-AzureAD再認証
    //   this.setState({ isForceRefresh: true, isTokenPopup: false });
    //   const token = await this.client.authenticate();
    //   this.setState({ isForceRefresh: false, isTokenPopup: true });

    //   // RefreshTokenで更新できない場合は再認証モーダルで再認証
    //   if (!token) {
    //     // 再認証終了までaccessTokenを使用する非同期処理を中断
    //     await loginPersistance.takeBinarySemaphore();
    //     Modal.warning({
    //       title: WARNING_TOKEN_TIMEOUT,
    //       centered: true,
    //       okText: '再認証',
    //       onOk: this.reCertification,
    //     });
    //   }
    // }

    /**
     * 再認証タイマー
     * @param {string} idToken IDトークン
     */
    // reCertificationTimer = (idToken) => {
    //   clearTimeout(this.timerHandler);
    //   const time = loginPersistance.getTokenValidityTime(idToken);
    //   this.timerHandler = setTimeout(this.reCertificationTimeout, time);
    // }

    /**
     * 認証関係のイベントを設定
     */
    configureAuthEventListener = () => {
      Hub.listen('auth', ({ payload: { event } }) => {
        switch (event) {
          case 'signOut':
            this.setState({ isLogin: false, loading: false });
            break;
          default:
            break;
        }
      });
    }

    /**
     * ログイン画面をレンダリングする。
     */
    renderLoginScreen() {
      const { form } = this.props;
      const { getFieldDecorator } = form;
      const { loading } = this.state;
      return (
        <div className="container">
          <div className="login-form">
            <div className="logo-area">
              <img className="logo" alt="logo" src="./img/logotext.png" />
            </div>

            <div className="login-form-items">
              <Form>
                <Form.Item className="login-form-item-format" label={FormItem.TenantName.label}>
                  {getFieldDecorator(FormItem.TenantName.name, {
                    rules: [
                      {
                        required: true,
                        message: VALIDATE_ERROR_TENANT_NAME_REQUIRE,
                      },
                    ],
                  })(<Input onPressEnter={this.handleClickLogin} />)}
                </Form.Item>
              </Form>
            </div>

            <div className="login-form-buttons">
              <Button
                type="primary"
                loading={loading}
                onClick={this.handleClickLogin}
              >
                ログイン
              </Button>
            </div>
          </div>
        </div>
      );
    }

    /**
     * ログイン状態を判定し、画面をレンダリングする。
     *
     * - ログイン状態: メイン画面
     * - 未ログイン状態: ログイン画面
     */
    render() {
      const { isLogin, isAuthenticated } = this.state;
      return (
        // <ClientSDKProvider client={this.client}>
        <PageContainer>
          {isLogin ? (
            !isAuthenticated && <Main roadproject={this.roadProject} isproject={this.isProject} grafanaurl={this.grafanaUrl} />
          ) : this.renderLoginScreen()}
        </PageContainer>
        // </ClientSDKProvider>
      );
    }
  },
);

export default AuthWrapper;
