import storage from '../../utils/localStorageCache';
import {
  EP_PATH_ROOT_ASSET_PROJECT,
  EP_PATH_ROOT_ASSET_AI_DETECT_RESULT,
  EP_PATH_ROOT_ASSET_TRAIN_TASK,
  EP_PATH_ROOT_ASSET_DETECT_TASK,
  EP_PATH_LEARNING_PJ,
} from '../../utils/AWS/EndpointPath';
import {
  getApiGateway,
  postApiGateway,
} from '../../utils/AWS/ApiGateway';

const PARENT_ASSETS_ID = 'parent-assets-id';
const PARENT_ASSETS_TYPE = 'parent-assets-type';
const MENU_KEY = 'menu-key';
const DATA_SET_ID = 'data_set_id';
const EQUIPMENT_CHECKED = 'equipment_checked';
const EQUIPMENT_NAME_CONDITIONS = 'equipment_name_conditions';
const TOKEN_EXPIRED = 'cognito-token-expired';

/**
 * 親AssetIdの設定
 * @param {number} assetId
 */
export function setStorageParentAssetId(assetId) {
  storage.setItem(PARENT_ASSETS_ID, assetId);
}

/**
 * 親AssetIdの取得
 * @returns {number} parentAssetId
 */
export function getStorageParentAssetId() {
  return storage.getItem(PARENT_ASSETS_ID);
}

/**
 * 親AssetIdの削除
 */
export function removeStorageParentAssetId() {
  storage.removeItem(PARENT_ASSETS_ID);
}

/**
 * 親Asset種別の設定
 * @param {string} assetType
 */
export function setStorageParentAssetType(assetType) {
  storage.setItem(PARENT_ASSETS_TYPE, assetType);
}

/**
 * 親Asset種別の取得
 * @returns {string} parentAssetType
 */
export function getStorageParentAssetType() {
  return storage.getItem(PARENT_ASSETS_TYPE);
}

/**
 * 親Asset種別の削除
 */
export function removeStorageParentAssetType() {
  storage.removeItem(PARENT_ASSETS_TYPE);
}

/**
 * MenuKeyの設定
 * @param {number} key
 */
export function setStorageMenuKey(key) {
  storage.setItem(MENU_KEY, key);
}

/**
 * MenuKeyの取得
 * @returns {number} menuKey
 */
export function getStorageMenuKey() {
  return storage.getItem(MENU_KEY);
}

/**
 * MenuKeyの削除
 */
export function removeStorageMenuKey() {
  storage.removeItem(MENU_KEY);
}

/**
 * dataSetIdの設定
 * @param {number} dataSetId
 */
export function setStorageDataSetId(dataSetId) {
  storage.setItem(DATA_SET_ID, dataSetId);
}

/**
 * dataSetIdの取得
 * @returns {number} dataSetId
 */
export function getStorageDataSetId() {
  return storage.getItem(DATA_SET_ID);
}

/**
 * dataSetIdの削除
 */
export function removeStorageDataSetId() {
  storage.removeItem(DATA_SET_ID);
}

/**
 * 管理設備チェックの設定
 * @param {number} equipmentChecked
 */
export function setStorageEquipmentChecked(equipmentChecked) {
  storage.setItem(EQUIPMENT_CHECKED, equipmentChecked);
}

/**
 * 管理設備チェックの取得
 * @returns {number} equipmentChecked
 */
export function getStorageEquipmentChecked() {
  return storage.getItem(EQUIPMENT_CHECKED);
}

/**
 * 管理設備チェックの削除
 */
export function removeStorageEquipmentChecked() {
  storage.removeItem(EQUIPMENT_CHECKED);
}


/**
 * 管理設備名検索条件の設定
 * @param {string} equipmentNameConditions
 */
export function setStorageEquipmentNameConditions(equipmentNameConditions) {
  storage.setItem(EQUIPMENT_NAME_CONDITIONS, equipmentNameConditions);
}

/**
 *  管理設備名検索条件の取得
 * @returns {string} equipmentNameConditions
 */
export function getStorageEquipmentNameConditions() {
  return storage.getItem(EQUIPMENT_NAME_CONDITIONS);
}

/**
 *  管理設備名検索条件の削除
 */
export function removeStorageEquipmentNameConditions() {
  storage.removeItem(EQUIPMENT_NAME_CONDITIONS);
}

/**
 * StorageDataの削除
 */
export function removeStorageData() {
  removeStorageParentAssetId();
  removeStorageParentAssetType();
  removeStorageMenuKey();
  removeStorageDataSetId();
  removeStorageEquipmentChecked();
  removeStorageEquipmentNameConditions();
}

/**
 * DL AssetIDの取得
 * @returns {number} dlAssetId
 */
export async function getDlAssetId() {
  const dataSetId = getStorageDataSetId();
  const scope = {
    filter: { dataSetIds: [{ id: dataSetId }], metadata: { assetType: 'deeplearning' } },
    limit: Infinity,
  };

  const assets = await postApiGateway(
    EP_PATH_ROOT_ASSET_PROJECT, scope,
  );
  return assets.items[0].id;
}

/**
 * Result AssetIDの取得
 * @returns {number} resultAssetId
 */
export async function getResultAssetId() {
  const dataSetId = getStorageDataSetId();
  const scope = {
    filter: { dataSetIds: [{ id: dataSetId }], metadata: { assetType: 'result' } },
  };

  const assets = await postApiGateway(
    EP_PATH_ROOT_ASSET_AI_DETECT_RESULT, scope,
  );

  return assets.items[0].id;
}

/**
 * TrainTask AssetIDの取得
 * @returns {number} trainTaskAssetId
 */
export async function getTrainTaskAssetId() {
  const dataSetId = getStorageDataSetId();
  const scope = {
    filter: { dataSetIds: [{ id: dataSetId }], metadata: { assetType: 'traintask' } },
  };

  const assets = await postApiGateway(
    EP_PATH_ROOT_ASSET_TRAIN_TASK, scope,
  );
  return assets.items[0].id;
}

/**
 * DetectTask AssetIDの取得
 * @returns {number} detectTaskAssetId
 */
export async function getDetectTaskAssetId() {
  const dataSetId = getStorageDataSetId();
  const scope = {
    filter: { dataSetIds: [{ id: dataSetId }], metadata: { assetType: 'detecttask' } },
  };

  const assets = await postApiGateway(
    EP_PATH_ROOT_ASSET_DETECT_TASK, scope,
  );
  return assets.items[0].id;
}

/**
 * ModelFile AssetIDの取得
 * @param {number} learningProjectId 選択した学習プロジェクトID
 * @returns {number} ModelFileAssetId
 */
export async function getModelFileIdByLearningProjectId(learningProjectId) {
  const getUrl = `${EP_PATH_LEARNING_PJ}/${learningProjectId}`;
  const assets = await getApiGateway(getUrl, { ignoreUnknownIds: true });
  return assets ? assets.id : undefined;
}

/**
 * トークン期限切れフラグの設定
 */
export function setTokenExpired() {
  storage.setItem(TOKEN_EXPIRED, true);
}

/**
 * トークン期限切れフラグの取得
 * @returns {boolean} トークン期限切れフラグ
 */
export function getTokenExpired() {
  return storage.getItem(TOKEN_EXPIRED);
}

/**
 * トークン期限切れフラグの削除
 */
export function removeTokenExpired() {
  storage.removeItem(TOKEN_EXPIRED);
}

export default {
  setStorageParentAssetId,
  getStorageParentAssetId,
  setStorageParentAssetType,
  getStorageParentAssetType,
  removeStorageParentAssetId,
  setStorageMenuKey,
  getStorageMenuKey,
  removeStorageMenuKey,
  setStorageDataSetId,
  getStorageDataSetId,
  removeStorageDataSetId,
  setStorageEquipmentChecked,
  getStorageEquipmentChecked,
  removeStorageEquipmentChecked,
  setStorageEquipmentNameConditions,
  getStorageEquipmentNameConditions,
  removeStorageEquipmentNameConditions,
  removeStorageData,
  getDlAssetId,
  getResultAssetId,
  getTrainTaskAssetId,
  getDetectTaskAssetId
};
