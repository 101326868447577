import {
  CogniteError,
  FileChangeUpdate,
  InternalId,
  ItemsWrapper,
} from '@cognite/sdk';
import { awsConfig } from '../../sdf-env.json';
import { retrieveAuthResult } from '../../components/AuthWrapper/persistance';
import { EP_PATH_LCCS_EXECUTE, EP_PATH_ROAD_COOKIES, EP_PATH_GENERATE_DIGITALREPORT_EXECUTE } from './EndpointPath';

/**
 * リクエストヘッダー
 */
export type RequestHeader = {
  Authorization: string,
  project: string,
}

/** ファイルダウンロードURLリクエスト */
export type RequestSpecifyID = ItemsWrapper<InternalId[]>;
/** ファイル更新リクエスト */
export type RequestFileChangeUpdate = { items: FileChangeUpdate[] }
/** エラーレスポンス */
export type ResponseCogniteError = { error: CogniteError };
/** 正常レスポンス */
export type ResponseCogniteItems<ResponseType> = { items: ResponseType };
/** 正常レスポンス（空の場合） */
export type EmptyResponse = Record<string, never>;
/** レスポンス */
export type ResponseCogniteOrError<ResponseType> =
  ResponseCogniteItems<ResponseType> | ResponseCogniteError;
/** ダウンロード */
export type ResponseDownload = { items: [{ id: number }] };

export type ResponseDownloadURL = { items: [{ id: number, downloadUrl: string }] };
/** 機能権限取得 */
export type RequestFunctionAuthentication = { project: string };

export type ResponseFunctionAuthentication = ItemsWrapper<{
  userRole: string, actions: string[], accessibleScreenList: string[],
}>;

/** テナント情報取得 */
export type PostTenantInfo = { project: string };
export type ResponseTenantInfo = string[] | undefined;

/** プロジェクトへのアクセス権限をチェック */
export type PostCheckProject = { project: string };
export type ResponseCheckProject = string[] | undefined;

/** プロジェクトに対するGrafanaUrl取得 */
export type PostGrafanaUrl = { project: string };
export type ResponseGrafanaUrl = string[] | undefined;

/** 署名付きcookie取得 */
export type PostSignedCookie = { project: string };
export type ResponseSignedCookie = string[] | undefined;

/** 署名付きcookie取得GET */
export type GetSignedCookieGet = { project: string };
export type ResponseSignedCookieGet = string[] | undefined;

/** LCCシミュレーション実行 */
export type PostExecuteLccs = { input: object, name: string, stateMachineArn: string };
export type ResponseExecuteLccs = string | undefined;

/** LCCシミュレーション状態確認 */
export type PostStatusLccs = { executionArn: string };
export type ResponseStatusLccs = number | undefined;

/** LCCシミュレーション設定ファイルアップロード */
export type PostUploadFilesLccs = { project: string };
export type ResponseUploadFilesLccs = string[] | undefined;

/** デジタルレポート作成実行 */
export type PostExecuteGenerateDigitalreport = { input: object, name: string, stateMachineArn: string };
export type ResponseExecuteGenerateDigitalreport = string | undefined;

/** デジタルレポート作成状態確認 */
export type PostStatusGenerateDigitalreport = { executionArn: string };
export type ResponseStatusGenerateDigitalreport = number | undefined;

/** POSTメソッド(ファイル用)
* @param endpoint エンドポイント
* @param scope 検索条件(CDFで定義されている型を想定)
* @param files ファイルの配列
* @returns 取得した要素の配列
*/
const postApiGatewayForFiles = async <T, E>(endpoint: string, scope: T, files: File[]): Promise<E> => {
  const body = new FormData();
  for (let i = 0; i < files.length; i++) {
    body.append('file' + i, files[i]);
  }
  body.append('scope', JSON.stringify(scope))
  const requestHeader = createRequestHeader();
  const requestUrl = createRequestUrl(endpoint);
  const response = await fetch(requestUrl, {
    method: 'POST',
    headers: requestHeader,
    body: body,
  });
  if (!response.ok) {
    // HTTP statusが200-299以外の場合
    throw new Error(String(response.status));
  }

  const responseData = await response.json();
  return responseData;
};

/**
 * POSTメソッド
 * @param endpoint エンドポイント
 * @param scope 検索条件(CDFで定義されている型を想定)
 * @returns 取得した要素の配列
 */
const postApiGateway = async <T, E>(endpoint: string, scope: T): Promise<E> => {
  const requestHeader = createRequestHeader();
  const requestUrl = createRequestUrl(endpoint);
  let response;
  if (endpoint == EP_PATH_ROAD_COOKIES) {
    response = await fetch(requestUrl, {
      method: 'POST',
      headers: requestHeader,
      body: JSON.stringify(scope),
      mode: 'cors',
      credentials: 'include',
    });
  } else if (endpoint == EP_PATH_LCCS_EXECUTE) {
    // @ts-ignore
    scope["input"]["headers"] = requestHeader
    scope["input"]["path"] = scope["path"]
    scope["input"]["yml"] = scope["yml"]
    // @ts-ignore
    scope["input"] = JSON.stringify(scope["input"])
    response = await fetch(requestUrl, {
      method: 'POST',
      headers: requestHeader,
      body: JSON.stringify(scope)
    });
  } else if (endpoint == EP_PATH_GENERATE_DIGITALREPORT_EXECUTE) {
    scope["input"]["headers"] = requestHeader
    scope["input"]["t_lcc_data_id"] = scope["t_lcc_data_id"]
    scope["input"] = JSON.stringify(scope["input"])
    response = await fetch(requestUrl, {
      method: 'POST',
      headers: requestHeader,
      body: JSON.stringify(scope)
    });
  } else {
    response = await fetch(requestUrl, {
      method: 'POST',
      headers: requestHeader,
      body: JSON.stringify(scope),
    });
  }

  if (!response.ok) {
    // HTTP statusが200-299以外の場合
    throw new Error(String(response.status));
  }

  const responseData = await response.json();
  return responseData;
};

/**
 * GETメソッド
 * @param {string} endpoint エンドポイント
 * @param {{ ignoreUnknownIds: boolean }} retrieveParam 不明なIDを無視するかの判定
 * @returns 取得した要素の配列
 */
const getApiGateway = async <E>(endpoint: string, retrieveParam?: { ignoreUnknownIds: boolean })
  : Promise<E | undefined> => {
  const requestHeader = createRequestHeader();
  const requestUrl = createRequestUrl(endpoint);
  const response = await fetch(requestUrl, {
    method: 'GET',
    headers: requestHeader,
  });
  if (!response.ok) {
    // HTTP statusが200-299以外の場合
    throw new Error(String(response.status));
  }

  const responseData = await response.json();

  // 不明なIDを無視する場合
  if (responseData.error && retrieveParam?.ignoreUnknownIds) return undefined;

  return responseData;
};

/**
 * GETメソッド(画像データ用)
 * @param endpoint エンドポイント
 * @returns 画像データ(base64形式)
 */
const getApiGatewayForImage = async (endpoint: string): Promise<string> => {
  const requestHeader = createRequestHeader();
  const requestUrl = createRequestUrl(endpoint);

  const response = await fetch(requestUrl, {
    method: 'GET',
    headers: requestHeader,
  });
  if (!response.ok) {
    // HTTP statusが200-299以外の場合
    throw new Error(String(response.status));
  }

  // API Gatewayからbase64形式の文字列で返却される
  const responseData = await response.text();
  return responseData;
};

/**
 * PUTメソッド
 * @param endpoint エンドポイント
 * @param data 追加・更新条件(CDFで定義されている型を想定)
 * @returns 取得した要素の配列
 */
const putApiGateway = async <T, E>(endpoint: string, data: T): Promise<E> => {
  const requestHeader = createRequestHeader();
  const requestUrl = createRequestUrl(endpoint);

  const response = await fetch(requestUrl, {
    method: 'PUT',
    headers: requestHeader,
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    // HTTP statusが200-299以外の場合
    throw new Error(String(response.status));
  }

  const responseData = await response.json();
  return responseData;
};

/**
 * DELETEメソッド
 * @param endpoint エンドポイント
 * @param data 削除条件(CDFで定義されている型を想定)
 */
const deleteApiGateway = async <T>(endpoint: string, data: T): Promise<void> => {
  const requestHeader = createRequestHeader();
  const requestUrl = createRequestUrl(endpoint);

  const response = await fetch(requestUrl, {
    method: 'DELETE',
    headers: requestHeader,
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    // HTTP statusが200-299以外の場合
    throw new Error(String(response.status));
  }
};

/**
 * リクエストヘッダーを作成する
 * @returns リクエストヘッダー
 */
const createRequestHeader = (): RequestHeader => {
  const authResult = retrieveAuthResult();
  const { project, awsIdToken } = authResult;

  const headers = {
    Authorization: awsIdToken,
    project,
  };
  return headers;
};

/**
 * リクエストURLを作成する。
 * @param endpoint エンドポイント
 * @returns リクエストURL
 */
const createRequestUrl = (endpoint: string): string => {
  const { region, apiGateway } = awsConfig;
  const { gatewayId, stage, domain } = apiGateway;
  // const requestUrl = `https://${gatewayId}.execute-api.${region}.amazonaws.com/${stage}${endpoint}`;
  const requestUrl = `https://${domain}${endpoint}`;
  return requestUrl;
};

export {
  postApiGateway,
  getApiGateway,
  getApiGatewayForImage,
  putApiGateway,
  deleteApiGateway,
  createRequestHeader,
  createRequestUrl,
  postApiGatewayForFiles,
};
