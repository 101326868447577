import {
  Asset, AssetListScope,
  CogniteInternalId,
  FileChangeUpdate, FileInfo,
  InternalId, ItemsWrapper,
} from '@cognite/sdk';

import {
  EP_PATH_PROJECTS_LIST,
  EP_PATH_AUTHORITY_LIST,
  EP_PATH_ROAD_CHECK_PROJECT,
  EP_PATH_ROAD_GRAFANA,
  EP_PATH_ROAD_COOKIES,
  EP_PATH_LCCS_EXECUTE,
  EP_PATH_LCCS_STATUS,
  EP_PATH_LCCS_UPLOAD_FILES,
  EP_PATH_GENERATE_DIGITALREPORT_EXECUTE,
  EP_PATH_GENERATE_DIGITALREPORT_STATUS
} from './EndpointPath';
import {
  ResponseCogniteItems,
  ResponseCogniteError,
  postApiGateway,
  ResponseFunctionAuthentication,
  RequestFunctionAuthentication,
  PostTenantInfo,
  ResponseTenantInfo,
  PostCheckProject,
  ResponseCheckProject,
  PostGrafanaUrl,
  ResponseGrafanaUrl,
  PostSignedCookie,
  ResponseSignedCookie,
  PostExecuteLccs,
  ResponseExecuteLccs,
  PostStatusLccs,
  ResponseStatusLccs,
  PostUploadFilesLccs,
  ResponseUploadFilesLccs,
  postApiGatewayForFiles,
  PostExecuteGenerateDigitalreport,
  ResponseExecuteGenerateDigitalreport,
  PostStatusGenerateDigitalreport,
  ResponseStatusGenerateDigitalreport
} from './ApiGateway';
import { ResourceType } from '../common/SDFDataType';
import {
  getManagedFacilityFilesIcon,
  retrieveManagedFacilityFile,
  updateManagedFacilityFile,
  deleteManagedFacilityFile,
  getManagedFacilityFileDownloadUrl,
} from '../File/ManagedFacilityFile';
import {
  getFacilityFilesIcon,
  retrieveFacilityFile,
  updateFacilityFile,
  deleteFacilityFile,
  getFacilityFileDownloadUrl,
} from '../File/FacilityFile';
import {
  deleteTeacherDataFile,
  getTeacherDataFilesIcon,
  retrieveTeacherDataFile,
  updateTeacherDataFile,
} from '../File/TeacherDataFile';
import { getLearningProjectFilesIcon, retrieveLearningProjectFile } from '../File/LearningProjectFile';
import { getAiDetectResultFilesIcon, retrieveAiDetectResultFile } from '../File/AIDetectResultFile';
import { loadAllManagedFacilityFromCDFByIds, loadAllManagedFacilityFromCDFByScope } from '../Asset/ManagedFacilityAsset';
import { loadAllDetectionResultsFromCDFByIds, loadAllDetectionResultsFromCDFByScope } from '../Asset/DetectionResultAsset';
import { loadAllSolarSiteFromCDFByIds, loadAllSolarSiteFromCDFByScope } from '../Asset/SolarSiteAsset';
import { retrieveFinancialReportFile } from '../File/FinancialReportFile';
import { awsConfig } from '../../sdf-env.json';

/**
 * リソース種別によってアセット情報を取得する。（ID指定）
 * @param resourceType リソース種別
 * @param ids IDリスト
 * @returns アセット情報
 */
const retrieveAsset = async (
  resourceType: ResourceType,
  ids: InternalId[],
): Promise<ItemsWrapper<Asset[]>> => {
  let assets: ItemsWrapper<Asset[]>;
  switch (resourceType) {
    case ResourceType.ManagedFacility:
      assets = await loadAllManagedFacilityFromCDFByIds(ids);
      break;
    case ResourceType.AIDetectResult:
      assets = await loadAllDetectionResultsFromCDFByIds(ids);
      break;
    case ResourceType.SolarSite:
      assets = await loadAllSolarSiteFromCDFByIds(ids);
      break;
    default:
      assets = { items: [] };
      break;
  }

  return assets;
};

/**
 * リソース種別によってアセット情報を取得する。
 * @param resourceType リソース種別
 * @param scope アセット検索条件
 * @returns アセット情報
 */
const getAssetList = async (
  resourceType: ResourceType,
  scope: AssetListScope,
): Promise<Asset[]> => {
  let assets: Asset[];
  switch (resourceType) {
    case ResourceType.ManagedFacility:
      assets = await loadAllManagedFacilityFromCDFByScope(scope);
      break;
    case ResourceType.AIDetectResult:
      assets = await loadAllDetectionResultsFromCDFByScope(scope);
      break;
    case ResourceType.SolarSite:
      assets = await loadAllSolarSiteFromCDFByScope(scope);
      break;
    default:
      assets = [];
      break;
  }

  return assets;
};

/**
 * リソース種別によってファイル情報を取得する。(ID指定)
 * @param resourceType リソース種別
 * @param id ID
 * @returns ファイル情報
 */
const retrieveFile = async (
  resourceType: ResourceType,
  id: CogniteInternalId,
): Promise<FileInfo | undefined> => {
  let fileInfo: FileInfo | undefined;
  switch (resourceType) {
    case ResourceType.ManagedFacility:
      fileInfo = await retrieveManagedFacilityFile(id);
      break;
    case ResourceType.Facility:
      fileInfo = await retrieveFacilityFile(id);
      break;
    case ResourceType.Training:
      fileInfo = await retrieveTeacherDataFile(id);
      break;
    case ResourceType.LearningProject:
      fileInfo = await retrieveLearningProjectFile(id);
      break;
    case ResourceType.AIDetectResult:
      fileInfo = await retrieveAiDetectResultFile(id);
      break;
    case ResourceType.SolarSite:
      fileInfo = await retrieveFinancialReportFile(id);
      break;
    default:
      break;
  }

  return fileInfo;
};

/**
 * リソース種別によってファイルアイコンを更新する。
 * @param resourceType リソース種別
 * @param id ID
 * @returns ファイルアイコン
 */
const getFilesIcon = async (
  resourceType: ResourceType,
  id: CogniteInternalId,
): Promise<string | undefined> => {
  let iconUrl: string | undefined;
  switch (resourceType) {
    case ResourceType.ManagedFacility:
      iconUrl = await getManagedFacilityFilesIcon(id);
      break;
    case ResourceType.Facility:
      iconUrl = await getFacilityFilesIcon(id);
      break;
    case ResourceType.Training:
      iconUrl = await getTeacherDataFilesIcon(id);
      break;
    case ResourceType.LearningProject:
      iconUrl = await getLearningProjectFilesIcon(id);
      break;
    case ResourceType.AIDetectResult:
      iconUrl = await getAiDetectResultFilesIcon(id);
      break;
    default:
      iconUrl = undefined;
      break;
  }

  return iconUrl;
};

/**
 * リソース種別によってファイルダウンロードURLを取得する。
 * @param resourceType リソース種別
 * @param id ID
 * @returns ファイルアイコン
 */
const getDownloadUrl = async (
  resourceType: ResourceType,
  id: CogniteInternalId,
): Promise<string> => {
  let downloadUrl: string;
  switch (resourceType) {
    case ResourceType.ManagedFacility:
      downloadUrl = await getManagedFacilityFileDownloadUrl(id);
      break;
    case ResourceType.Facility:
      downloadUrl = await getFacilityFileDownloadUrl(id);
      break;
    default:
      downloadUrl = '';
      break;
  }

  return downloadUrl;
};

/**
 * リソース種別によってファイル情報を更新する。
 * @param resourceType リソース種別
 * @param changes ファイル更新情報
 * @returns 更新後のファイル情報
 */
const updateFile = async (
  resourceType: ResourceType,
  changes: FileChangeUpdate,
): Promise<ResponseCogniteItems<FileInfo[]>> => {
  let updatedFiles: ResponseCogniteItems<FileInfo[]>;
  switch (resourceType) {
    case ResourceType.ManagedFacility:
      updatedFiles = await updateManagedFacilityFile(changes);
      break;
    case ResourceType.Facility:
      updatedFiles = await updateFacilityFile(changes);
      break;
    case ResourceType.Training:
      updatedFiles = await updateTeacherDataFile(changes);
      break;
    default:
      updatedFiles = { items: [] };
      break;
  }

  return updatedFiles;
};

/**
 * リソース種別によってファイルを削除する。
 * @param resourceType リソース種別
 * @param id ファイルID
 */
const deleteFile = async (
  resourceType: ResourceType,
  id: CogniteInternalId,
): Promise<void> => {
  switch (resourceType) {
    case ResourceType.ManagedFacility:
      await deleteManagedFacilityFile(id);
      break;
    case ResourceType.Facility:
      await deleteFacilityFile(id);
      break;
    case ResourceType.Training:
      await deleteTeacherDataFile(id);
      break;
    default:
      break;
  }
};

/**
 * テナントにアクセス権限を持っているか判定する。
 * @param tenant テナント名
 * @returns アクセス権限がある場合: 権限グループ, ない場合: undefined
 */
const hasPermissionToProject = async (tenant: string): Promise<ResponseTenantInfo> => {
  let authorityGroup;
  const scope = {
    project: tenant,
  };

  try {
    authorityGroup = await postApiGateway<PostTenantInfo, string[]>(
      EP_PATH_PROJECTS_LIST,
      scope,
    );
  } catch {
    return undefined;
  }

  return authorityGroup;
};

/**
 * テナント上での各機能への各種権限を取得する。
 * @param tenant テナント名
 * @returns ユーザロール、各画面/機能に対する権限
 */
const getPermissionToFunction = async (tenant: string):
  Promise<ResponseFunctionAuthentication | ResponseCogniteError> => {
  const scope = {
    project: tenant,
  };
  const getAuthentication = await postApiGateway<
    RequestFunctionAuthentication,
    ResponseFunctionAuthentication | ResponseCogniteError
  >(EP_PATH_AUTHORITY_LIST, scope);

  return getAuthentication;
};

/**
 * プロジェクトへのアクセス権限をチェックする。
 * @param tenant テナント名（プロジェクト名）
 * @returns 権限の有無
 */
const checkProject = async (tenant: string): Promise<ResponseCheckProject> => {
  let isAuthority;
  const scope = {
    project: tenant,
  };

  try {
    isAuthority = await postApiGateway<PostCheckProject, string[]>(
      EP_PATH_ROAD_CHECK_PROJECT,
      scope,
    );
  } catch {
    return undefined;
  }

  return isAuthority;
};

/**
 * プロジェクトに対するgrafanaURLを取得する。
 * @param tenant プロジェクト名
 * @returns プロジェクトに対するgrafanaURL
 */
const getGrafanaUrl = async (tenant: string): Promise<ResponseGrafanaUrl> => {
  let grafanaUrl;
  const scope = {
    project: tenant,
  };

  try {
    grafanaUrl = await postApiGateway<PostGrafanaUrl, string[]>(
      EP_PATH_ROAD_GRAFANA,
      scope,
    );
  } catch {
    return undefined;
  }

  return grafanaUrl;
};

/**
 * cookieを取得する。
 * @param tenant テナント名
 * @returns ユーザー権限に対する道路プロジェクト
 */
const setSignedCookie = async (tenant: string): Promise<ResponseSignedCookie> => {
  const scope = {
    project: tenant,
  };

  try {
    await postApiGateway<PostSignedCookie, string[]>(
      EP_PATH_ROAD_COOKIES,
      scope,
    );
  } catch {
    return undefined;
  }
};

/**
 * LCCSを実行する。
 * @param tenant テナント名
 * @param s3path 設定ファイルの保存先パス
 * @param selectedFileName 設定ファイル名
 * @returns LCCS ECSの実行ID
 */
const executeLccs = async (tenant: string, s3path: string, selectedFileName: string): Promise<ResponseExecuteLccs> => {
  const date = new Date()
  const { region, accountId } = awsConfig;
  const scope = {
    input: {},
    name: date.toLocaleString().replace(/ |\/|:/g, '_'),
    path: s3path,
    yml: selectedFileName,
    stateMachineArn: `arn:aws:states:${region}:${accountId}:stateMachine:road-lcc-simulation-ecs`,
  }

  try {
    const arn = await postApiGateway<PostExecuteLccs, string>(
      EP_PATH_LCCS_EXECUTE,
      scope
    );
    return arn
  } catch (e) {
    console.error(e);
    return undefined
  }
};

/**
 * LCCSの状態を取得する。
 * @param arn LCCS ECSの実行ID
 * @returns LCCS実行後のステータスコード
 */
const statusLccs = async (arn: string): Promise<ResponseStatusLccs> => {
  return new Promise(async (resolve, reject) => {
    let status;
    const scope = {
      executionArn: arn
    };
    try {    //10秒ごとに実行
      const getStatus = setInterval(async () => {
        status = await postApiGateway<PostStatusLccs, string[]>(
          EP_PATH_LCCS_STATUS,
          scope
        );
        let output: any = {}
        if ("output" in status) {
          // LCCSから帰ってきたステータスコードを取得
          const strOutput: any = status["output"]
          output = JSON.parse(strOutput);
          if (output != null && "statusCode" in output) {
            console.log("LCCS実行完了")
            clearInterval(getStatus)
            resolve(output)
            return;
          } else {
            console.log("LCCS実行エラー")
            clearInterval(getStatus)
            output["statusCode"] = 400
            resolve(output)
            return;
          }
        } else if ("error" in status) {
          // LCCS実行エラー
          console.log("LCCS実行エラー")
          clearInterval(getStatus)
          output["statusCode"] = Number(status["error"])
          resolve(output)
          return;
        } else if ("status" in status && status["status"] != "RUNNING") {
          // LCCS実行以前のエラー
          console.log("LCCS実行エラー")
          clearInterval(getStatus)
          output["statusCode"] = 400
          resolve(output)
          return;
        } else {
          console.log("LCCS実行中")
        }
      }, 10000);
    } catch (e) {
      console.error(e)
      reject(e)
    }
  })
};

/**
 * LCCSの設定ファイルをS3にアップロードする。
 * @param tenant テナント名
 * @param tenant ファイル配列
 * @returns なし
 */
const uploadFilesLccs = async (tenant: string, files: File[]): Promise<ResponseUploadFilesLccs> => {
  const scope = {
    project: tenant
  }
  try {
    const result = await postApiGatewayForFiles<PostUploadFilesLccs, string[]>(
      EP_PATH_LCCS_UPLOAD_FILES,
      scope,
      files
    );
    return result
  } catch (e) {
    console.error(e);
    alert("アップロードが失敗しました。アップロードファイルを確認してください。")
    return undefined
  }
};

const executeGenerateDigitalreport = async (tenant: string, t_lcc_data_id: string): Promise<ResponseExecuteGenerateDigitalreport> => {
  const date = new Date()
  const { region, accountId } = awsConfig;
  const scope = {
    input: {},
    name: date.toLocaleString().replace(/ |\/|:/g, '_'),
    t_lcc_data_id: t_lcc_data_id,
    stateMachineArn: `arn:aws:states:${region}:${accountId}:stateMachine:sdf-road-generate-digitalreport`,
  }

  try {
    const arn = await postApiGateway<PostExecuteGenerateDigitalreport, string>(
      EP_PATH_GENERATE_DIGITALREPORT_EXECUTE,
      scope
    );
    return arn
  } catch (e) {
    console.error(e);
    return undefined
  }
};

const statusGenerateDigitalreport = async (arn: string): Promise<ResponseStatusGenerateDigitalreport> => {
  return new Promise(async (resolve, reject) => {
    let status;
    const scope = {
      executionArn: arn
    };
    try {    //10秒ごとに実行
      const getStatus = setInterval(async () => {
        status = await postApiGateway<PostStatusGenerateDigitalreport, string[]>(
          EP_PATH_GENERATE_DIGITALREPORT_STATUS,
          scope
        );
        if ("output" in status) {
          // デジタルレポート作成のStepFunctionsから帰ってきたステータスコードを取得
          const output: any = status["output"]
          const dict = JSON.parse(output);
          if (dict != null && "statusCode" in dict) {
            const statusCode = dict["statusCode"]
            console.log("デジタルレポート作成実行完了")
            clearInterval(getStatus)
            resolve(statusCode)
            return;
          } else {
            console.log("デジタルレポート作成実行エラー")
            clearInterval(getStatus)
            let statusCode = 400
            resolve(statusCode)
            return;
          }
        } else if ("status" in status && status["status"] != "RUNNING") {
          // CZML作成エラー
          console.log("デジタルレポート作成実行前エラー")
          clearInterval(getStatus)
          let statusCode = 400
          resolve(statusCode)
          return;
        } {
          console.log("デジタルレポート作成中")
        }
      }, 10000);
    } catch (e) {
      console.error(e)
      reject(e)
    }
  })
};

export {
  retrieveAsset,
  getAssetList,
  retrieveFile,
  getFilesIcon,
  getDownloadUrl,
  updateFile,
  deleteFile,
  hasPermissionToProject,
  getPermissionToFunction,
  checkProject,
  getGrafanaUrl,
  setSignedCookie,
  executeLccs,
  statusLccs,
  uploadFilesLccs,
  executeGenerateDigitalreport,
  statusGenerateDigitalreport
};

