import React from 'react';
import {
  Table, Icon, Button, Modal, Spin,
} from 'antd';
import { MimeType } from '../../utils/File/BaseFile';
import { ERROR_READ_NOTIFICATION } from '../../utils/messages';

const PATH_OF_ANNOUNCEMENT_LIST = '/announcement/announcements.json';

const { Html } = MimeType;

/**
 * メンテナンス情報テーブルのスクロール領域高さ(1行=54px(リンク有り65px))
 */
const MAINTENANCE_INFORMATION_SCROLL_Y = 270;

const ERROR_READ_NOTIFICATION_JSON = [{
  key: 1,
  type: 'failure',
  startTime: '－',
  endTime: '－',
  title: ERROR_READ_NOTIFICATION,
}];

/**
 * お知らせ表示
 */
const AnnouncementList = () => {
  /**
   * 変数/定数定義
   */
  const [announcements, setAnnouncements] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [target, setTarget] = React.useState();
  const [InfomationOfDetail, setInfomationOfDetail] = React.useState();
  const [modalIsVisible, setModalIsVisible] = React.useState(false);

  /**
   * イベントハンドラ
   */
  React.useEffect(
    () => {
      loadAnnouncements();
    },
    [],
  );

  /**
   * 内容のリンククリック時のイベントハンドラ
   * @param {array} announcement クリックしたお知らせ
   */
  const onLinkClick = (announcement) => {
    setTarget(announcement);
    setInfomationOfDetail(<object aria-label="詳細情報" data={announcement.detailPath} type={Html} width="100%" height="400px" />);
    setModalIsVisible(true);
  };

  /**
   * Modalキャンセル時のイベントハンドラ
   */
  const onCancelModal = () => {
    setModalIsVisible(false);
  };

  /**
   * お知らせ一覧の読み込み
   */
  async function loadAnnouncements() {
    setLoading(true);
    await fetch(PATH_OF_ANNOUNCEMENT_LIST)
      .then((response) => response.json())
      .then((responseJson) => {
        setAnnouncements(responseJson);
      })
      .catch(() => {
        setAnnouncements(ERROR_READ_NOTIFICATION_JSON);
      });
    setLoading(false);
  }

  /**
   * 画面描画
   */
  const columns = [
    {
      title: '種別',
      dataIndex: 'type',
      width: 150,
      render: (_text, announcement, _index) => {
        let type = null;
        switch (announcement.type) {
          case 'maintenance':
            type = <span>メンテナンス</span>;
            break;
          case 'failure':
            type = <span>故障</span>;
            break;
          case 'update':
            type = <span>アップデート</span>;
            break;
          default:
            type = <span>その他</span>;
            break;
        }
        return type;
      },
    },
    {
      title: '日時',
      dataIndex: 'dateTime',
      width: 450,
      render: (_text, announcement, _index) => (
        <span>
          {
            announcement.startTime !== announcement.endTime
              ? `${announcement.startTime}～${announcement.endTime}`
              : `${announcement.startTime}`
          }
        </span>
      ),
    },
    {
      title: '内容',
      dataIndex: 'title',
      render: (_text, announcement, _index) => (
        announcement.detailPath
          ? (
            <span>
              <Button
                type="link"
                onClick={onLinkClick.bind(this, announcement)}
              >
                {announcement.title}
              </Button>
            </span>
          )
          : <span style={{ paddingLeft: '15px' }}>{announcement.title}</span>
      ),
    },
  ];

  return (
    <div>
      <div style={{ marginBottom: '10px' }}>
        <Icon type="info-circle" />
        &nbsp;
        <span>お知らせ</span>
        <Button
          type="link"
          icon="reload"
          size='large'
          onClick={loadAnnouncements.bind(this)}
        />
      </div>
      <Table
        loading={loading}
        columns={columns}
        dataSource={announcements}
        pagination={false}
        scroll={{ y: MAINTENANCE_INFORMATION_SCROLL_Y }}
      />
      <Modal
        visible={modalIsVisible}
        title={target ? target.title : ''}
        footer={null}
        centered
        destroyOnClose
        onCancel={onCancelModal.bind(this)}
        width="1200px"
      >
        <Spin spinning={loading}>
          {InfomationOfDetail}
        </Spin>
      </Modal>
    </div>
  );
};

export default AnnouncementList;
