import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Menu } from 'antd';
import './MenuParts.css';

const { SubMenu } = Menu;

/**
 * MenuIconの引数
 * @param {string} src アイコンのアドレス
 * @param {string} alt 代替テキスト
 */
interface MenuIconProps {
  src: string,
  alt: string,
}

/**
 * MenuTextの引数
 * @param {string} title メニュータイトル
 * @param {boolean} collapsed Menu展開識別 (true: 折りたたみ / false: 展開)
 */
interface MenuTextProps {
  title: string,
  collapsed: boolean,
}

/**
 * MenuRowの引数
 * @param {string} to componentを切り替えるアドレス
 * @param {string} icon 表示するicon path
 * @param {string} title タイトル
 * @param {boolean} collapsed Menu展開識別 (true: 折りたたみ / false: 展開)
 */
interface MenuRowProps {
  to: string,
  icon: string,
  title: string,
  collapsed: boolean,
}

/**
 * SubMenuRowの引数
 * @param {string} key SubMenu key
 * @param {string} icon 表示するicon path
 * @param {string} title タイトル
 * @param {boolean} collapsed Menu展開識別 (true: 折りたたみ / false: 展開)
 * @param {JSX.Element[]} subMenuItems サブメニューの子要素
 */
interface SubMenuRowProps {
  key: string,
  icon: string,
  title: string,
  collapsed: boolean,
  subMenuItems: JSX.Element[],
}

/**
 * Menuアイコン作成
 * @param {MenuIconProps} props 引数
 * @returns Menu用imgタグ
 */
export const MenuIcon = (props: MenuIconProps): JSX.Element => {
  const {
    src,
    alt,
  } = props;
  return (<img src={src} alt={alt} style={{ width: '20px' }} />);
};

/**
 * Menuテキスト作成
 * @param {MenuTextProps} props 引数
 * @returns Menu用spanタグ
 */
export const MenuText = (props: MenuTextProps): JSX.Element => {
  const {
    title,
    collapsed,
  } = props;
  return (
    <>
      {!collapsed && <span className="non-collapsed-submenu">{title}</span>}
    </>
  );
};

/**
 * Menu１行作成
 * @param {MenuRowProps} props 引数
 * @returns Menu用Linkタグ
 */
export const MenuRow = (props: MenuRowProps): JSX.Element => {
  const {
    to,
    icon,
    title,
    collapsed,
  } = props;

  return (
    <Link to={to}>
      <Row>
        <MenuIcon src={icon} alt={title} />
        <MenuText title={title} collapsed={collapsed} />
      </Row>
    </Link>
  );
};

/**
 * SubMenu作成
 * @param {SubMenuRowProps} props 引数
 * @returns SubMenuタグ
 */
export const SubMenuRow = (props: SubMenuRowProps): JSX.Element => {
  const {
    key,
    icon,
    title,
    collapsed,
    subMenuItems,
    ...others
  } = props;
  return (
    <SubMenu
      key={key}
      title={(
        <span>
          <MenuIcon src={icon} alt={title} />
          <MenuText title={title} collapsed={collapsed} />
        </span>
      )}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...others}
    >
      {subMenuItems}
    </SubMenu>
  );
};
