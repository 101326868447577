/* 管理設備に関するエンドポイント */
export const EP_PATH_MANAGED_FACILITY = '/managed-facility';
export const EP_PATH_MANAGED_FACILITY_LIST = `${EP_PATH_MANAGED_FACILITY}/list`;
export const EP_PATH_MANAGED_FACILITY_LIST_BY_IDS = `${EP_PATH_MANAGED_FACILITY}/byids`;
export const EP_PATH_MANAGED_FACILITY_FILES = `${EP_PATH_MANAGED_FACILITY}/files`;
export const EP_PATH_MANAGED_FACILITY_FILES_ICON = `${EP_PATH_MANAGED_FACILITY_FILES}/icon`;
export const EP_PATH_MANAGED_FACILITY_FILES_LIST = `${EP_PATH_MANAGED_FACILITY_FILES}/list`;
export const EP_PATH_MANAGED_FACILITY_FILES_AGGREGATE = `${EP_PATH_MANAGED_FACILITY_FILES}/aggregate`;
export const EP_PATH_MANAGED_FACILITY_FILES_DOWNLOAD = `${EP_PATH_MANAGED_FACILITY_FILES}/download`;
export const EP_PATH_MANAGED_FACILITY_TIMESERIES_AGGREGATE = `${EP_PATH_MANAGED_FACILITY}/timeseries/aggregate`;
export const EP_PATH_MANAGED_FACILITY_TIMESERIES_LIST = `${EP_PATH_MANAGED_FACILITY}/timeseries/list`;
export const EP_PATH_MANAGED_FACILITY_TIMESERIES_DATAPOINTS_LIST = `${EP_PATH_MANAGED_FACILITY}/timeseries/datapoints/list`;

/* 設備に関するエンドポイント */
export const EP_PATH_FACILITY = '/facility';
export const EP_PATH_FACILITY_AGGREGATE = `${EP_PATH_FACILITY}/aggregate`;
export const EP_PATH_FACILITY_FILES = `${EP_PATH_FACILITY}/files`;
export const EP_PATH_FACILITY_FILES_DOWNLOAD = `${EP_PATH_FACILITY_FILES}/download`;
export const EP_PATH_FACILITY_FILES_LIST = `${EP_PATH_FACILITY_FILES}/list`;
export const EP_PATH_FACILITY_FILES_ICON = `${EP_PATH_FACILITY_FILES}/icon`;

/* 教師データに関するエンドポイント */
export const EP_PATH_TEACHER_DATA = '/teacher-data';
export const EP_PATH_TEACHER_DATA_LIST = `${EP_PATH_TEACHER_DATA}/list`;
export const EP_PATH_TEACHER_DATA_FILES = `${EP_PATH_TEACHER_DATA}/files`;
export const EP_PATH_TEACHER_DATA_FILES_ICON = `${EP_PATH_TEACHER_DATA_FILES}/icon`;
export const EP_PATH_TEACHER_DATA_FILES_LIST = `${EP_PATH_TEACHER_DATA_FILES}/list`;
export const EP_PATH_TEACHER_DATA_FILES_DOWNLOAD = `${EP_PATH_TEACHER_DATA_FILES}/download`;
export const EP_PATH_TEACHER_DATA_AGGREGATE = `${EP_PATH_TEACHER_DATA}/aggregate`;

/* 学習プロジェクトに関するエンドポイント */
export const EP_PATH_LEARNING_PJ = '/learning-project';
export const EP_PATH_LEARNING_PJ_AGGREGATE = `${EP_PATH_LEARNING_PJ}/aggregate`;
export const EP_PATH_LEARNING_PJ_LIST = `${EP_PATH_LEARNING_PJ}/list`;
export const EP_PATH_LEARNING_PJ_FILES = `${EP_PATH_LEARNING_PJ}/files`;
export const EP_PATH_LEARNING_PJ_FILES_ICON = `${EP_PATH_LEARNING_PJ_FILES}/icon`;
export const EP_PATH_LEARNING_PJ_FILES_LIST = `${EP_PATH_LEARNING_PJ_FILES}/list`;
export const EP_PATH_LEARNING_PJ_FILES_DOWNLOAD = `${EP_PATH_LEARNING_PJ_FILES}/download`;

/* アノテーションに関するエンドポイント */
export const EP_PATH_ANNOTATION = '/annotation';
export const EP_PATH_ANNOTATION_FILES = `${EP_PATH_ANNOTATION}/files`;
export const EP_PATH_ANNOTATION_FILES_DOWNLOAD = `${EP_PATH_ANNOTATION_FILES}/download`;

/* AI学習に関するエンドポイント */
export const EP_PATH_AI_LEARNING_EXECUTE = '/ai-learning/execute';

/* アンサンブルモデルに関するエンドポイント */
export const EP_PATH_ENSEMBLE_MODEL = '/ensemble-model';

/* AI検出に関するエンドポイント */
export const EP_PATH_AI_DETECT = '/ai-detect';
export const EP_PATH_AI_DETECT_EXECUTE = `${EP_PATH_AI_DETECT}/execute`;
export const EP_PATH_AI_DETECT_FILES_DOWNLOAD = `${EP_PATH_AI_DETECT}/files/download`;

/* AI検出結果に関するエンドポイント */
export const EP_PATH_AI_DETECT_RESULT = '/ai-detect-result';
export const EP_PATH_AI_DETECT_RESULT_AGGREGATE = `${EP_PATH_AI_DETECT_RESULT}/aggregate`;
export const EP_PATH_AI_DETECT_RESULT_LIST = `${EP_PATH_AI_DETECT_RESULT}/list`;
export const EP_PATH_AI_DETECT_RESULT_LIST_BY_IDS = `${EP_PATH_AI_DETECT_RESULT}/byids`;
export const EP_PATH_AI_DETECT_RESULT_FILES = `${EP_PATH_AI_DETECT_RESULT}/files`;
export const EP_PATH_AI_DETECT_RESULT_FILES_ICON = `${EP_PATH_AI_DETECT_RESULT_FILES}/icon`;
export const EP_PATH_AI_DETECT_RESULT_FILES_LIST = `${EP_PATH_AI_DETECT_RESULT_FILES}/list`;
export const EP_PATH_AI_DETECT_RESULT_FILES_AGGREGATE = `${EP_PATH_AI_DETECT_RESULT_FILES}/aggregate`;
export const EP_PATH_AI_DETECT_RESULT_FILES_DOWNLOAD = `${EP_PATH_AI_DETECT_RESULT_FILES}/download`;

/* 学習タスクに関するエンドポイント */
export const EP_PATH_TRAIN_TASK = '/train-task';
export const EP_PATH_TRAIN_TASK_LIST = `${EP_PATH_TRAIN_TASK}/list`;
export const EP_PATH_TRAIN_TASK_FILES = `${EP_PATH_TRAIN_TASK}/files`;
export const EP_PATH_TRAIN_TASK_FILES_DOWNLOAD = `${EP_PATH_TRAIN_TASK_FILES}/download`;

/* 検出タスクに関するエンドポイント */
export const EP_PATH_DETECT_TASK = '/detect-task';
export const EP_PATH_DETECT_TASK_LIST = `${EP_PATH_DETECT_TASK}/list`;
export const EP_PATH_DETECT_TASK_FILES = `${EP_PATH_DETECT_TASK}/files`;
export const EP_PATH_DETECT_TASK_FILES_DOWNLOAD = `${EP_PATH_DETECT_TASK_FILES}/download`;
export const EP_PATH_DETECT_TASK_ENSEMBLE_MODEL_FILES = `${EP_PATH_DETECT_TASK}/ensemble-model/files`;
export const EP_PATH_DETECT_TASK_AI_MODEL_FILES = `${EP_PATH_DETECT_TASK}/ai-learning/files`;

/* ダウンロードステータスに関するエンドポイント */
export const EP_PATH_DL_STATUS = '/dl-status';

/* 点検結果に関するエンドポイント */
export const EP_PATH_INSPECTION_RESULT = '/inspection-result';
export const EP_PATH_INSPECTION_RESULT_LIST = `${EP_PATH_INSPECTION_RESULT}/list`;
export const EP_PATH_INSPECTION_RESULT_FILES = `${EP_PATH_INSPECTION_RESULT}/files`;
export const EP_PATH_INSPECTION_RESULT_FILES_DOWNLOAD = `${EP_PATH_INSPECTION_RESULT_FILES}/download`;
export const EP_PATH_INSPECTION_RESULT_FILE_AGGREGATE = `${EP_PATH_INSPECTION_RESULT}/files/aggregate`;

/* 学習モデルに関するエンドポイント */
export const EP_PATH_LEARNING_MODEL = '/learning-model';
export const EP_PATH_LEARNING_MODEL_PARAMETER_FILES = `${EP_PATH_LEARNING_MODEL}/files/parameter`;
export const EP_PATH_LEARNING_MODEL_MODEL_AGGREGATE = `${EP_PATH_LEARNING_MODEL}/files/model/aggregate`;

/* 学習モデル(AIモデル)に関するエンドポイント */
export const EP_PATH_LEARNING_MODEL_AI_MODEL = `${EP_PATH_LEARNING_MODEL}/ai-learning`;
export const EP_PATH_LEARNING_MODEL_AI_MODEL_LIST = `${EP_PATH_LEARNING_MODEL}/ai-learning/list`;
export const EP_PATH_LEARNING_MODEL_AI_MODEL_FILES = `${EP_PATH_LEARNING_MODEL}/ai-learning/files`;
export const EP_PATH_LEARNING_MODEL_AI_MODEL_FILES_LIST = `${EP_PATH_LEARNING_MODEL_AI_MODEL_FILES}/list`;

/* 学習モデル(アンサンブルモデル)に関するエンドポイント */
export const EP_PATH_LEARNING_MODEL_ENSEMBLE_MODEL_FILES = `${EP_PATH_LEARNING_MODEL}/ensemble-model/files`;
export const EP_PATH_LEARNING_MODEL_ENSEMBLE_MODEL_FILES_LIST = `${EP_PATH_LEARNING_MODEL_ENSEMBLE_MODEL_FILES}/list`;

/* 学習モデル(cocoファイル)に関するエンドポイント */
export const EP_PATH_LEARNING_MODEL_FILES_COCO_AGGREGATE = `${EP_PATH_LEARNING_MODEL}/files/coco/aggregate`;

/* 学習モデル(configファイル)に関するエンドポイント */
export const EP_PATH_LEARNING_MODEL_FILES_CONFIG_DOWNLOAD = `${EP_PATH_LEARNING_MODEL}/files/config/download`;
export const EP_PATH_LEARNING_MODEL_FILES_CONFIG_AGGREGATE = `${EP_PATH_LEARNING_MODEL}/files/config/aggregate`;

/* 学習モデル(parameterファイル)に関するエンドポイント */
export const EP_PATH_LEARNING_MODEL_FILES_PARAMETER_DOWNLOAD = `${EP_PATH_LEARNING_MODEL}/files/parameter/download`;

/* プロジェクトに関するエンドポイント */
export const EP_PATH_PROJECTS_LIST = '/projects/list';

/* ログファイルに関するエンドポイント */
export const EP_PATH_LOG_FILE = '/log-file';
export const EP_PATH_LOG_FILE_AGGREGATE = `${EP_PATH_LOG_FILE}/aggregate`;

/* ルートアセットに関するエンドポイント */
const EP_PATH_ROOT_ASSET = '/root-asset';
export const EP_PATH_ROOT_ASSET_PROJECT = `${EP_PATH_ROOT_ASSET}/project`;
export const EP_PATH_ROOT_ASSET_TRAIN_TASK = `${EP_PATH_ROOT_ASSET}/train-task`;
export const EP_PATH_ROOT_ASSET_DETECT_TASK = `${EP_PATH_ROOT_ASSET}/detect-task`;
export const EP_PATH_ROOT_ASSET_AI_DETECT_RESULT = `${EP_PATH_ROOT_ASSET}/ai-detect-result`;
export const EP_PATH_ROOT_ASSET_RESULT_LIST = `${EP_PATH_ROOT_ASSET}/result/list`;
export const EP_PATH_ROOT_ASSET_LIST = `${EP_PATH_ROOT_ASSET}/list`;

/* 全体(集計)に関するエンドポイント */
export const EP_PATH_ASSET_AGGREGATE = '/assets/aggregate';
export const EP_PATH_EVENTS_AGGREGATE = '/events/aggregate';
export const EP_PATH_FILES_AGGREGATE = '/files/aggregate';
export const EP_PATH_TIMESERIES_AGGREGATE = '/timeseries/aggregate';

/* 権限に関するエンドポイント */
export const EP_PATH_AUTHORITY_LIST = '/authority/list';

/* 太陽光ダッシュボードに関するエンドポイント */
const EP_PATH_SOLAR = '/solar';
export const EP_PATH_SOLAR_ASSETS = `${EP_PATH_SOLAR}/assets`;
export const EP_PATH_SOLAR_ASSETS_BY_IDS = `${EP_PATH_SOLAR_ASSETS}/byids`;
export const EP_PATH_SOLAR_ASSETS_LIST = `${EP_PATH_SOLAR_ASSETS}/list`;
export const EP_PATH_SOLAR_EVENTS = `${EP_PATH_SOLAR}/events`;
export const EP_PATH_SOLAR_EVENTS_LIST = `${EP_PATH_SOLAR_EVENTS}/list`;
export const EP_PATH_SOLAR_FILES = `${EP_PATH_SOLAR}/files`;
export const EP_PATH_SOLAR_FILES_LIST = `${EP_PATH_SOLAR_FILES}/list`;
export const EP_PATH_SOLAR_FILES_DOWNLOAD = `${EP_PATH_SOLAR_FILES}/download`;
export const EP_PATH_SOLAR_TIMESERIES_DATAPOINTS = `${EP_PATH_SOLAR}/timeseries/datapoints`;
const EP_PATH_SOLAR_PROCESSES = `${EP_PATH_SOLAR}/processes`;
export const EP_PATH_SOLAR_PROCESSES_PRESUMED_ELECTRIC_ENERGY = `${EP_PATH_SOLAR_PROCESSES}/presumed-electric-energy`;
export const EP_PATH_SOLAR_PROCESSES_PLANNED_FINANCE = `${EP_PATH_SOLAR_PROCESSES}/planned-finance`;
export const EP_PATH_SOLAR_PROCESSES_ACTUAL_FINANCE = `${EP_PATH_SOLAR_PROCESSES}/actual-finance`;

/* 道路運営管理に関するエンドポイント */
const EP_PATH_ROAD = '/road-management';
export const EP_PATH_ROAD_CHECK_PROJECT = `${EP_PATH_ROAD}/check-project`;
export const EP_PATH_ROAD_GRAFANA = `${EP_PATH_ROAD}/grafana`;
export const EP_PATH_ROAD_COOKIES = `${EP_PATH_ROAD}/signed-cookie`;

/* LCCSに関するエンドポイント */
const EP_PATH_LCCS = '/road-management/lccs';
export const EP_PATH_LCCS_EXECUTE = `${EP_PATH_LCCS}/execute`;
export const EP_PATH_LCCS_STATUS = `${EP_PATH_LCCS}/execute/status`;
export const EP_PATH_LCCS_UPLOAD_FILES = `${EP_PATH_LCCS}/upload-files`;

/* デジタルレポート作成に関するエンドポイント */
const EP_PATH_GENERATE_DIGITALREPORT = '/road-management/generate-digitalreport';
export const EP_PATH_GENERATE_DIGITALREPORT_EXECUTE = `${EP_PATH_GENERATE_DIGITALREPORT}/execute`;
export const EP_PATH_GENERATE_DIGITALREPORT_STATUS = `${EP_PATH_GENERATE_DIGITALREPORT}/execute/status`;
